/*
The !important declaration is required in this case in order to preserve
the default values of the .letter-tile and .drawer-tile class. When
a user doesn't have a specific font set, the default value will be used.

* Note: Since tile color is NOT set by default on the .letter-tile/.drawer-tile
class, the following tile colors do not require !important.

*/


/*= Default Tile Colors
========================================*/

.t-bv { /* Basic Vowels */
    background: #d34755; /* Old browsers */
    background: -moz-linear-gradient(top,  #d34755 0%, #ab1f2c 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d34755), color-stop(100%,#ab1f2c)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #d34755 0%,#ab1f2c 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d34755', endColorstr='#ab1f2c',GradientType=0 ); /* IE6-9 */
}

.t-bc { /* Basic Consonants */
    background: #ffd456; /* Old browsers */
    background: -moz-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffd456), color-stop(100%,#ffc600)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* IE10+ */
    background: linear-gradient(to bottom, #ffd456 0%,#ffc600 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd456', endColorstr='#ffc600',GradientType=0 ); /* IE6-9 */
    color: #1b1b1b;
}

.t-rcv { /* R-Controlled Vowels */
    background: #d34755; /* Old browsers */
    background: -moz-linear-gradient(top,  #d34755 0%, #ab1f2c 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d34755), color-stop(100%,#ab1f2c)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #d34755 0%,#ab1f2c 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d34755', endColorstr='#ab1f2c',GradientType=0 ); /* IE6-9 */
}

.t-cdt { /* Consonant Digraphs & Trigraphs */
    background: #ffd456; /* Old browsers */
    background: -moz-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffd456), color-stop(100%,#ffc600)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* IE10+ */
    background: linear-gradient(to bottom, #ffd456 0%,#ffc600 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd456', endColorstr='#ffc600',GradientType=0 ); /* IE6-9 */
    color: #1b1b1b;
}

.t-vt { /* Vowel Teams */
    background: #d34755; /* Old browsers */
    background: -moz-linear-gradient(top,  #d34755 0%, #ab1f2c 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d34755), color-stop(100%,#ab1f2c)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #d34755 0%,#ab1f2c 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d34755', endColorstr='#ab1f2c',GradientType=0 ); /* IE6-9 */
}

.t-bl { /* Bonus Letters */
    background: #ffd456; /* Old browsers */
    background: -moz-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffd456), color-stop(100%,#ffc600)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* IE10+ */
    background: linear-gradient(to bottom, #ffd456 0%,#ffc600 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd456', endColorstr='#ffc600',GradientType=0 ); /* IE6-9 */
    color: #1b1b1b;
}

.t-gs { /* Glued Sounds */
    background: #48ad5e; /* Old browsers */
    background: -moz-linear-gradient(top,  #48ad5e 0%, #2c8b3c 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#48ad5e), color-stop(100%,#2c8b3c)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #48ad5e 0%,#2c8b3c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #48ad5e 0%,#2c8b3c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #48ad5e 0%,#2c8b3c 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #48ad5e 0%,#2c8b3c 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#48ad5e', endColorstr='#2c8b3c',GradientType=0 ); /* IE6-9 */
}

.t-slc { /* Silent Letter Combinations */
    background: #ffd456; /* Old browsers */
    background: -moz-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffd456), color-stop(100%,#ffc600)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* IE10+ */
    background: linear-gradient(to bottom, #ffd456 0%,#ffc600 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd456', endColorstr='#ffc600',GradientType=0 ); /* IE6-9 */
    color: #1b1b1b;
}

.t-avs { /* Advanced Vowel Sounds */
    background: #d34755; /* Old browsers */
    background: -moz-linear-gradient(top,  #d34755 0%, #ab1f2c 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d34755), color-stop(100%,#ab1f2c)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #d34755 0%,#ab1f2c 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d34755', endColorstr='#ab1f2c',GradientType=0 ); /* IE6-9 */
}

.t-acs { /* Advanced Consonant Sounds */
    background: #ffd456; /* Old browsers */
    background: -moz-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffd456), color-stop(100%,#ffc600)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* IE10+ */
    background: linear-gradient(to bottom, #ffd456 0%,#ffc600 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd456', endColorstr='#ffc600',GradientType=0 ); /* IE6-9 */
    color: #1b1b1b;
}

.t-ags { /* Advanced Glued Sounds */
    background: #48ad5e; /* Old browsers */
    background: -moz-linear-gradient(top,  #48ad5e 0%, #2c8b3c 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#48ad5e), color-stop(100%,#2c8b3c)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #48ad5e 0%,#2c8b3c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #48ad5e 0%,#2c8b3c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #48ad5e 0%,#2c8b3c 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #48ad5e 0%,#2c8b3c 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#48ad5e', endColorstr='#2c8b3c',GradientType=0 ); /* IE6-9 */
}

.t-pref { /* Prefixes */
    background: #4c72aa; /* Old browsers */
    background: -moz-linear-gradient(top,  #4c72aa 0%, #1f57ab 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#4c72aa), color-stop(100%,#1f57ab)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #4c72aa 0%,#1f57ab 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #4c72aa 0%,#1f57ab 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #4c72aa 0%,#1f57ab 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #4c72aa 0%,#1f57ab 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c72aa', endColorstr='#1f57ab',GradientType=0 ); /* IE6-9 */
}

.t-suff { /* Suffixes */
    background: #4c72aa; /* Old browsers */
    background: -moz-linear-gradient(top,  #4c72aa 0%, #1f57ab 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#4c72aa), color-stop(100%,#1f57ab)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #4c72aa 0%,#1f57ab 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #4c72aa 0%,#1f57ab 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #4c72aa 0%,#1f57ab 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #4c72aa 0%,#1f57ab 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c72aa', endColorstr='#1f57ab',GradientType=0 ); /* IE6-9 */
}

.t-sym { /* Symbols */
    background: #696493; /* Old browsers */
    background: -moz-linear-gradient(top,  #696493 0%, #504795 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#696493), color-stop(100%,#504795)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #696493 0%,#504795 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #696493 0%,#504795 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #696493 0%,#504795 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #696493 0%,#504795 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#696493', endColorstr='#504795',GradientType=0 ); /* IE6-9 */
}

.t-sight { /* Sight Words */
    background: #898881; /* Old browsers */
    background: -moz-linear-gradient(top,  #898881 0%, #6e6d67 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#898881), color-stop(100%,#6e6d67)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #898881 0%,#6e6d67 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #898881 0%,#6e6d67 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #898881 0%,#6e6d67 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #898881 0%,#6e6d67 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#898881', endColorstr='#6e6d67',GradientType=0 ); /* IE6-9 */
}




/*= All Possible Tile Font Colors
========================================*/

.fc-black {
    color: #1b1b1b !important;
}

.fc-white {
    color: #ffffff !important;
}

.fc-red {
    color: #EC1414 !important;
}

/*= All Possible Font Families
========================================*/
/* NOTE: Gecko/Opera/Webkit browsers will wait until
/* a font-family declaration happens before downloading
/* the font so declare as many fonts as you want! Yay!
*/



/*= All Possible Blackboard Background Colors
================================================*/

.bg-default {
    background: #e1e6ec; /* Old browsers */
    background: -moz-linear-gradient(top, #e1e6ec 0%, #d9dee4 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e1e6ec), color-stop(100%,#d9dee4)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #e1e6ec 0%,#d9dee4 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #e1e6ec 0%,#d9dee4 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #e1e6ec 0%,#d9dee4 100%); /* IE10+ */
    background: linear-gradient(to bottom, #e1e6ec 0%,#d9dee4 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1e6ec', endColorstr='#d9dee4',GradientType=0 ); /* IE6-9 */
}

.bg-transparent {
    background: rgba(0, 0, 0, 0);
}

.bg-black {
    background: #000;
}

.bg-white {
    background: #fff;
}

.bg-blue {
    background: rgb(207, 215, 255);
}

.bg-green {
    background: rgb(215, 255, 232);
}

.bg-yellow {
    background: rgb(255, 255, 209);
}

.bg-cream {
    background: rgb(244, 241, 222);
}

.bg-pink {
    background: rgb(255, 225, 227);
}

/*= Gradient Shadow Colors (Aligned with all possible background colors)
================================================*/
.sbg-default {
    background:
        /* Shadow covers */
        linear-gradient(#e1e6ec 30%, rgba(255,255,255,0)),
        linear-gradient(rgba(255,255,255,0), #e1e6ec 70%) 0 100%,
                linear-gradient(90deg, #e1e6ec 30%, rgba(255,255,255,0)),
        linear-gradient(90deg, rgba(255,255,255,0), #e1e6ec 70%) 100% 0,

        /* Shadows */
        radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.5), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.5), rgba(0,0,0,0)) 0 100%,
        radial-gradient(farthest-side at 0 50%, rgba(0,0,0,.4), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 100% 50%, rgba(0,0,0,.4), rgba(0,0,0,0)) 100% 0;
    background-repeat: no-repeat;
    background-color: #e1e6ec;
    background-size: 100% 40px, 100% 40px, 40px 100%, 40px 100%, 100% 14px, 100% 14px, 14px 100%, 14px 100%;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, local, local, scroll, scroll, scroll, scroll;
}
.sbg-black {
    background:
        /* Shadow covers */
        linear-gradient(#000 30%, rgba(255,255,255,0)),
        linear-gradient(rgba(255,255,255,0), #000 70%) 0 100%,
                linear-gradient(90deg, #000 30%, rgba(255,255,255,0)),
        linear-gradient(90deg, rgba(255,255,255,0), #000 70%) 100% 0,

        /* Shadows */
        radial-gradient(farthest-side at 50% 0, rgba(255,255,255,.5), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 50% 100%, rgba(255,255,255,.5), rgba(0,0,0,0)) 0 100%,
        radial-gradient(farthest-side at 0 50%, rgba(255,255,255,.4), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 100% 50%, rgba(255,255,255,.4), rgba(0,0,0,0)) 100% 0;
    background-repeat: no-repeat;
    background-color: #000;
    background-size: 100% 40px, 100% 40px, 40px 100%, 40px 100%, 100% 14px, 100% 14px, 14px 100%, 14px 100%;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, local, local, scroll, scroll, scroll, scroll;
}

/*= All Possible Tile Highlight Colors
================================================*/

.thc-blue:active {
    box-shadow: 0px 0px 30px #004aff !important;
}

.thc-red {
    box-shadow: 0px 0px 30px #ab1f2c !important;
}

.thc-yellow {
    box-shadow: 0px 0px 30px #ffe400 !important;
}

.thc-green {
    box-shadow: 0px 0px 30px #1eff00 !important;
}

.thc-purple {
    box-shadow: 0px 0px 30px #fc00ff !important;
}

.thc-orange {
    box-shadow: 0px 0px 30px #ff8a00 !important;
}

.thc-pink {
    box-shadow: 0px 0px 30px #ff00ae !important;
}
