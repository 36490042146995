@font-face {
    font-family: 'OpenDyslexic';
    src: url('../fonts/OpenDyslexic-Bold.eot?') format('eot'), url('../fonts/OpenDyslexic-Bold.woff') format('woff'), url('../fonts/OpenDyslexic-Bold.ttf') format('truetype');
  }
  
@font-face {
    font-family: 'Century Gothic';
    src: url('../fonts/CenturyGothic.eot?') format('eot'), url('../fonts/CenturyGothic.woff') format('woff'), url('assets/fonts/CenturyGothic.ttf') format('truetype');
    font-weight: bold;
}

.ff-century {
    font-family: 'Century Gothic', 'Arial', sans-serif !important;
}

.ff-arial {
    font-family: 'Arial', sans-serif !important;
}

.ff-helvetica {
    font-family: 'Helvetica', sans-serif !important;
}

.ff-opendyslexic {
    font-family: 'OpenDyslexic', 'Arial', sans-serif !important;
}