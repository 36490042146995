
// fonts

$primary-font: "Roboto", sans-serif;
$secondary-font: "Roboto", sans-serif;
$flashcard-font: "Arial", sans-serif;

//theme color

$primary-color:#210854;
$secondary-color:#fff;

// Interface Colors
$interface-color-darkar:#210854;
$interface-color-darkar-medium:#481e9f;
$interface-color-light:#8e70ff;
$interface-color-light-medium:#866dff;
$interface-color-light-soft:#e4e2ed;
$interface-color-soft-darkar:#ff7463;
$interface-color-soft-medium:#ffb5a0;
$modal-title-darkar:#0d0223;
$body-background:#e4e2ed;
$active-account-color:#ffb5a0;
$ellipsis-icon-color:#cac4d7;
$paragraph:#777183;
$newworkbook-bg: rgba(134, 109, 255, 0.04);
$blank-div-bg-color: rgba(134, 109, 255, 0.08);
$font-color-light: rgba(13, 2, 35, 0.56);

// Tile Colors
$grapefruit:#cf1b3a;
$light-peach:#fec9bf;
$light-mustard:#ffca5a;
$light-beige:#ffe79d;
$soft-green:#00ab44;
$very-pale-green:#ccf29c;
$soft-blue:#568aff;
$pale-sky-blue:#83bfe7;
$periwinkle:#8e70ff;
$pale-lilac:#e4a9ff;
$orangeish:#ff8e42;
$battleship-grey:#7c7c74;
$bubblegum:#ff76b8;
$off-white:#f6efda;
$c-transparent:rgba(0, 0, 0, 0);
$c-clear:rgba(0, 0, 0, 0);

$rotate: #210854;

//border

$border-dash-image:url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
$border-pale-grey: #f1edf8;

//box shadow

$pad-box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
$frozen-row-shadow: 0 2px 4px 0 #dfd8f4;
$box-defult-shadow: 0 4px 16px 0 rgba($interface-color-darkar, 0.08);
$modal-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36);

//button color

$import-btn-font-color:#210854;
$manage-course-button-bg-color:#ffb5a0;
$manage-course-button-border-color:#ffb5a0;
$manage-course-button-font-color:#210854;

$fill-button-bg-color:#866dff;
$fill-button-border-color:#866dff;
$fill-button-font-color:#fff;

$transparent-button-bg-color: transparent;
$transparent-button-font-color: #866dff;

$sharing-done-button-bg-color:#ffb5a0;
$sharing-done-button-font-color:#210854;

$begin-lesson-button-bg-color:#ffb5a0;
$begin-lesson-button-font-color:#210854;

$time-reading-score-button-bg-color:#ffb5a0;
$time-reading-score-button-font-color:#210854;

$registration-login-button-bg-color:#ffb5a0;
$registration-login-button-font-color:#210854;

$register-button-bg-color:#210854;
$register-button-font-color:#fff;



//Activity header icon active bg

$read-button-active-bg: #f1eeff;

// Card Width

$card-width-1920: 19.2%;
$card-width-1680: 18.8%;
$card-width-1366: 18.8%;
$card-width-1280: 23.5%;
$card-width-1024: 31%;
$workbook-width-content: 23.6%;
$whizzimolibrary-card: 18.8%;
$whizzimolibrary-card-1280: 23.5%;
$whizzimolibrary-card-1024: 31%;

// Card Height

$card-height: 118px;
$workbook-height-content: 374px;

//Activity header modal bg

// $move-bg: "assets/img/theme1/move-modal.png";

// common icon

$down-icon: "assets/img/theme1/down.png";
$up-icon: "assets/img/up.png";
$resizable-handle-icon: "assets/img/theme1/down-white.png";
$search-icon: "assets/img/theme1/search.png";
$settings-icon: "assets/img/theme1/setting-2.png";

//Light Theme
// .light-theme{
@import "_theme-light-variable.scss";
@import "../themes.scss";
// }

