@use 'node_modules/@angular/material' as mat;
@use '/node_modules/@angular/material/theming';
@import '../assets/scss/maintheme.scss';

@include mat.core();

$whizzimo-orange: #FF8F2C;
$whizzimo-purple: #866DFF;

// .light-theme{
    $whizzimo-primary: mat.define-palette($md-whizzimo, 500);
    $whizzimo-primary-light: mat.define-palette($md-whizzimo-light, 100);
    $whizzimo-primary-dark: mat.define-palette($md-whizzimo-dark, 500);
    $whizzimo-primary-dark-menu: mat.define-palette($md-whizzimo-dark, 500);
    $whizzimo-primary-dark-background-color: mat.define-palette($md-whizzimo-light, A400);
    $whizzimo-primary-dark-background: mat.define-palette($md-whizzimo-dark-mode, 500);
    $whizzimo-primary-menu-active-background: mat.define-palette($md-whizzimo-dark-mode, 900);
    $whizzimo-modal-background-color: mat.define-palette($md-whizzimo-light, A100);
    $primary-dark-activity-item-child-color: mat.define-palette($md-whizzimo-dark-mode, 100);
    $whizzimo-mat-tab-label-color: mat.define-palette($md-whizzimo-dark-mode, 900);
    $whizzimo-primary-form-field-outline-color: mat.define-palette($md-whizzimo-dark-mode, A800);
    $whizzimo-primary-form-field-color: mat.define-palette($md-whizzimo-dark-mode, 800);
    $whizzimo-black-white-color: mat.define-palette($md-whizzimo-dark-mode, A800);
    $whizzimo-font-light-color: mat.define-palette($md-whizzimo-dark-mode, 100);
    $whizzimo-blackboard-body-color: mat.define-palette($md-whizzimo-light, 500);
    $whizzimo-input-background-color: mat.define-palette($md-whizzimo-light, A100);
    $whizzimo-border-color: mat.define-palette($md-whizzimo-dark-mode, 200);
    $whizzimo-light-color: mat.define-palette($md-whizzimo, A100);
    $whizzimo-white-darkblue-color: mat.define-palette($md-whizzimo-light, A100);
    $whizzimo-black-lightblue-color: mat.define-palette($md-whizzimo, A100);
    $whizzimo-darkblue-white-color: mat.define-palette($md-whizzimo-dark-mode, 800);
    $whizzimo-menu-content-color: mat.define-palette($md-whizzimo, A100);
    $whizzimo-dark-purple-color: mat.define-palette($md-whizzimo-dark, 500);
    $whizzimo-modal-title-darkar-color: mat.define-palette($md-whizzimo-dark-mode, 800);
    $whizzimo-blnak-new-course-color: mat.define-palette($md-whizzimo-dark-mode, 400);
    $whizzimo-grey-white-color: mat.define-palette($md-whizzimo-dark-mode, 50);
    $whizzimo-lightpurpale-color: mat.define-palette($md-whizzimo-dark-mode, A200);
    $whizzimo-lightgrey-white-color: mat.define-palette($md-whizzimo-dark-mode, 100);



    $whizzimo-background: mat.define-palette(mat.$gray-palette, 100);
    $whizzimo-warn: mat.define-palette(mat.$deep-orange-palette, 300);

    $whizzimo-theme: mat.define-light-theme($whizzimo-primary, $whizzimo-primary-dark, $whizzimo-warn);
    $whizzimo-dark-theme: mat.define-dark-theme($whizzimo-primary, $whizzimo-primary-dark, $whizzimo-warn);

    $primary: mat.get-color-from-palette($whizzimo-primary);
    $warn: mat.get-color-from-palette($whizzimo-warn, 100);
    $primary-light: mat.get-color-from-palette($whizzimo-primary-light);
    $primary-dark: mat.get-color-from-palette($whizzimo-primary-dark);
    $primary-dark-menu: mat.get-color-from-palette($whizzimo-primary-dark-menu);
    $primary-dark-background-color: mat.get-color-from-palette($whizzimo-primary-dark-background-color);
    $primary-dark-background: mat.get-color-from-palette($whizzimo-primary-dark-background);
    $whizzimo-menu-item-active: mat.get-color-from-palette($whizzimo-primary-menu-active-background);
    $whizzimo-modal-background: mat.get-color-from-palette($whizzimo-modal-background-color);
    $primary-dark-activity-item-child: mat.get-color-from-palette($primary-dark-activity-item-child-color);
    $whizzimo-mat-tab-label: mat.get-color-from-palette($whizzimo-mat-tab-label-color);
    $whizzimo-form-field-outline-color: mat.get-color-from-palette($whizzimo-primary-form-field-outline-color);
    $whizzimo-form-field-color: mat.get-color-from-palette($whizzimo-primary-form-field-color);
    $whizzimo-black-white: mat.get-color-from-palette($whizzimo-black-white-color);
    $whizzimo-font-light: mat.get-color-from-palette($whizzimo-font-light-color);
    $whizzimo-blackboard-body: mat.get-color-from-palette($whizzimo-blackboard-body-color);
    $whizzimo-input-background: mat.get-color-from-palette($whizzimo-input-background-color);
    $whizzimo-border: mat.get-color-from-palette($whizzimo-border-color);
    $whizzimo-light: mat.get-color-from-palette($whizzimo-light-color);
    $whizzimo-white-darkblue: mat.get-color-from-palette($whizzimo-white-darkblue-color);
    $whizzimo-black-lightblue: mat.get-color-from-palette($whizzimo-black-lightblue-color);
    $whizzimo-darkblue-white: mat.get-color-from-palette($whizzimo-darkblue-white-color);
    $whizzimo-menu-content: mat.get-color-from-palette($whizzimo-menu-content-color);
    $whizzimo-dark-purple: mat.get-color-from-palette($whizzimo-dark-purple-color);
    $whizzimo-modal-title-darkar: mat.get-color-from-palette($whizzimo-modal-title-darkar-color);
    $whizzimo-blnak-new-course: mat.get-color-from-palette($whizzimo-blnak-new-course-color);
    $whizzimo-grey-white: mat.get-color-from-palette($whizzimo-grey-white-color);
    $whizzimo-lightpurpale: mat.get-color-from-palette($whizzimo-lightpurpale-color);
    $whizzimo-lightgrey-white: mat.get-color-from-palette($whizzimo-lightgrey-white-color);



    $background: mat.get-color-from-palette($whizzimo-background);
    $transparent-66: rgba($primary, 0.66);
    $transparent-33: rgba($primary, 0.33);
    $whizzimo-activity-item-child: rgba($primary-dark-activity-item-child, 0.87);
    $whizzimo-form-field-outline: rgba($whizzimo-form-field-outline-color, 0.12);
    $whizzimo-input-background-transparent: rgba($whizzimo-input-background, 1);
    $whizzimo-border-transparent: rgba($whizzimo-border, 0.32);


    //Activity header icon

$read-button: "assets/img/theme1/light-book.png";
$edit-button: "assets/img/theme1/light-pencil.png";
$erase-button: "assets/img/theme1/light-erase.png";
$undo-button: "assets/img/theme1/light-undo.png";
$keyboard-button: "assets/img/theme1/light-keyboard.png";
$format-size-button: "assets/img/theme1/light-capitalize.png";
$visibility-button: "assets/img/theme1/light-show.png";
$aspect-ratio-button: "assets/img/theme1/light-resize.png";
$bumping-tiles-button: "assets/img/theme1/light-tile-bumping-onoff.png";
$delete-button: "assets/img/theme1/light-delete.png";
$info-button: "assets/img/theme1/light-help.png";
// $color-button: "assets/img/theme1/light-color.png";
$visibility-hidden: "assets/img/theme1/light-hide.png";
$assignment-button: "assets/img/theme1/light-lines-onoff.png";
$search-plus: "assets/img/theme1/light-zoom-in.png";
$search-minus: "assets/img/theme1/light-zoom-out.png";
$view-array-button: "assets/img/theme1/light-tiles.png";
$landscape-button: "assets/img/theme1/light-whole-word.png";
$folder-button: "assets/img/theme1/light-page.png";
$blender-icon: "assets/img/theme1/light-blender.png";

//Activity header icon active

$read-button-active: "assets/img/theme1/light-book-active.png";
$edit-button-active: "assets/img/theme1/light-pencil-active.png";
$erase-button-active: "assets/img/theme1/light-erase-active.png";
$undo-button-active: "assets/img/theme1/light-undo-active.png";
$keyboard-button-active: "assets/img/theme1/light-keyboard-active.png";
$format-size-button-active: "assets/img/theme1/light-capitalize-active.png";
$visibility-button-active: "assets/img/theme1/light-show-active.png";
$aspect-ratio-button-active: "assets/img/theme1/light-resize-active.png";
$bumping-tiles-button-active: "assets/img/theme1/light-tile-bumping-onoff-active.png";
$delete-button-active: "assets/img/theme1/light-delete-active.png";
$info-button-active: "assets/img/theme1/light-help-active.png";
// $color-button-active: "assets/img/theme1/light-color-active.png";
$assignment-button-active: "assets/img/theme1/light-lines-onoff-active.png";
$search-plus-active: "assets/img/theme1/light-zoom-in-active.png";
$search-minus-active: "assets/img/theme1/light-zoom-out-active.png";
$view-array-button-active: "assets/img/theme1/light-tiles-active.png";
$landscape-button-active: "assets/img/theme1/light-whole-word-active.png";
$blender-icon-active: "assets/img/theme1/light-blender-active.png";

$add-circle: "assets/img/theme1/add-circle-1.png";

$move-bg: "assets/img/theme1/move-modal.png";
