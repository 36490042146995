// mixin css
// @import "variable/_variable-v1.scss";
// @import "variable/_variable.scss";
// @import '~@angular/material/theming';
// @import './assets/scss/variables';
// @import './assets/scss/maintheme';

// @include mat-core();

// $whizzimo-orange: #FF8F2C;
// $whizzimo-purple: #866DFF;



// responsive screen resolution
$largedesktopview: 1920px;
$mediamdesktopview: 1680px;
$smalldesktopview: 1366px;
$extrasmalldesktopview: 1280px;
$tabView: 1024px;
$primary-font: "Roboto", sans-serif;

// responsive screen resolution

// define header and subheader

@mixin h1-header {
    font-family: $primary-font;
    font-size: 32px;
    color: $primary-dark !important;
	font-weight: bold;
    letter-spacing: -0.01em;
}
@mixin h2-header {
    font-family: $primary-font;
    font-size: 20px;
    color: $primary-dark;
	font-weight: bold;
    letter-spacing: -0.01em;
}
@mixin h3-header {
    font-family: $primary-font;
    font-size: 16px;
    color: $primary-dark;
	font-weight: bold;
    letter-spacing: -0.01em;
}
@mixin paragraph {
    font-family: $secondary-font;
    font-size: 16px;
    color: $paragraph;
}

//border



//Elements

//button

@mixin manage-course-button {
    background-color: $manage-course-button-bg-color !important;
    border: 1px solid $manage-course-button-border-color;
    border-radius: 4px;
    .mdc-button__label{
        line-height:40px !important;
        color: $manage-course-button-font-color;
        font-size: 14px;
        font-weight: bold;
		text-align: center;
		text-transform: uppercase;
    }
}
@mixin fill-button {
    background-color: $fill-button-bg-color;
    border: 1px solid $fill-button-border-color;
    border-radius: 4px;
    .mdc-button__label{
        line-height:40px !important;
        // color: $fill-button-font-color;
        font-size: 14px;
        font-family: $primary-font;
        text-align: center;
        font-weight: 500;
		letter-spacing: 1.2px;
		text-transform: uppercase;
    }
}
@mixin transparent-button {
    background-color: $transparent-button-bg-color;
    border: 1px solid $transparent-button-bg-color;
    border-radius: 4px;
    .mdc-button__label{
        line-height:40px !important;
        color: $transparent-button-font-color;
        font-size: 14px;
        font-family: $primary-font;
        text-align: center;
        font-weight: 500;
		letter-spacing: 0.4px;
		text-transform: uppercase;
    }
}
@mixin modal-cancel-button {
    background-color: $transparent-button-bg-color;
    border: 1px solid $transparent-button-bg-color;
    border-radius: 4px;
    .mdc-button__label{
        line-height:40px !important;
        color: $transparent-button-font-color;
        font-size: 14px;
        font-family: $primary-font;
        text-align: center;
        font-weight: 500;
		letter-spacing: 0.4px;
		text-transform: uppercase;

    }
}
@mixin modal-save-button {
    background-color: $transparent-button-bg-color;
    border: 1px solid $transparent-button-bg-color;
    border-radius: 4px;
    .mdc-button__label{
        line-height:40px !important;
        color: $transparent-button-font-color;
        font-size: 14px;
        font-family: $primary-font;
        text-align: center;
        font-weight: 500;
		letter-spacing: 0.4px;
		text-transform: uppercase;
    }
}
@mixin sharing-done-button {
    background-color: $sharing-done-button-bg-color !important;
    border: 1px solid $sharing-done-button-bg-color;
    border-radius: 4px;
    .mdc-button__label{
        line-height:40px !important;
        color: $primary-dark;
        font-size: 14px;
        font-family: $primary-font;
        text-align: center;
        font-weight: 500;
		letter-spacing: 0.4px;
		text-transform: uppercase;
    }
}
@mixin begin-lesson-button {
    background-color: $begin-lesson-button-bg-color;
    border-radius: 4px;
    .mdc-button__label{
        line-height:40px !important;
        color: $begin-lesson-button-font-color;
        font-size: 14px;
        font-family: $primary-font;
        text-align: center;
        font-weight: 500;
		letter-spacing: 0.4px;
		text-transform: uppercase;
    }
}
@mixin time-reading-score-button {
    background-color: $time-reading-score-button-bg-color;
    border-radius: 4px;
    .mdc-button__label{
        line-height: 1em !important;
        color: $time-reading-score-button-font-color;
        font-size: 14px;
        font-family: $primary-font;
        text-align: center;
        font-weight: 600;
		letter-spacing: 0.4px;
		text-transform: uppercase;
    }
}
@mixin no-file-btn{
	border: none;
	.mdc-button__label{
		color: $interface-color-light-medium;
		font-size: 14px;
		text-transform: uppercase;
	}
}
@mixin registration-login-button {
    background-color: $registration-login-button-bg-color;
	border-radius: 4px;
	color: $registration-login-button-font-color;
	font-size: 14px;
	font-family: $primary-font;
	text-align: center;
	font-weight: bold;
	letter-spacing: 0.4px;
	text-transform: uppercase;
	padding: 12px 19px;
}
@mixin register-button {
    background-color: $register-button-bg-color;
	border-radius: 4px;
	box-shadow: none !important;
	padding: 14px 17px;
	color: $register-button-font-color;
	font-size: 14px;
	font-family: $primary-font;
	text-align: center;
	font-weight: 500;
	letter-spacing: 0.4px;
	text-transform: uppercase;
	outline: 0;
	border: none;
}


//mixin css//

//global css//
html, body {
	margin: 0;
	padding: 0;
	font-family: $primary-font;
	height: 100%;
	background-color: $primary-dark-background;
}
button {
	letter-spacing: normal !important;
}
.container{
	height: 100%;
	width: 100%;
	// background-color: $primary-dark-menu;//rgba(0, 0, 0, 0) !important;
	position: relative;
}
.mat-mdc-focus-indicator {
	background: transparent;
}
.blanktiles {
	min-height: 100px !important;
}
.flashcard {
	position: absolute;
	font-family: $flashcard-font;
	font-size: 120px;
	line-height: 6rem;
	font-weight: bold;
	display: flex !important;
	align-items: center;
	justify-content: center;
	height: 20vh;
	min-height: 433px;
	// text-align: center;
	padding: 2.5% 15px;
	flex-shrink: 3;
	border-radius: 8px ;
	box-shadow: 0 4px 16px 0 rgba($primary-dark, 0.08);
	// background-color: $primary-dark-background-color;
	color: $primary-dark ;
	@media only screen and (max-width: $extrasmalldesktopview){
		height: auto !important;
	}
	background-color: white;

	.dark-theme & {
		background-color: #18112f !important;
	}
}
.flashcard-altered{
	// position: absolute;
	font-family: $flashcard-font;
	font-size: 120px;
	line-height: 6rem;
	font-weight: bold;
	display: flex !important;
	align-items: center;
	justify-content: center;
	height: 20vh;
	min-height: 433px;
	// text-align: center;
	padding: 2.5% 15px;
	flex-shrink: 3;
	border-radius: 8px ;
	box-shadow: 0 4px 16px 0 rgba($primary-dark, 0.08);
	background-color: $primary-dark-background-color;
	color: $primary-dark ;
	@media only screen and (max-width: $extrasmalldesktopview){
		height: auto !important;
	}
}

.mat-mdc-form-field-label {
	color: rgba($whizzimo-black-white, 0.87);
}
.mat-progress-bar-fill::after {
	background-color: $interface-color-light-medium;
}
.mat-tab-group.mat-primary{
	.mat-ink-bar{
		background-color: $interface-color-light-medium;
	}
}
.tally-box{
	.tally{
		font-size: 16px !important;
		.tally_index{
			color: $primary-dark;
		}
	}
}
.char-limit {
	color: #a6a6a6;
	font-size: 12px;
	text-align: left;
	margin-left: 13px;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused{
	.mdc-notched-outline__leading,
	.mdc-notched-outline__notch,
	.mdc-notched-outline__trailing {
		border-color: $interface-color-light-medium;
	}
	.mat-mdc-form-field-label{
		color: $interface-color-light-medium;
	}
	.mat-mdc-form-field-ripple{
		background-color: $interface-color-light-medium;
	}
	.mat-select-arrow{
		color: $interface-color-light-medium !important;
	}
}
.mat-input-element {
	caret-color: $interface-color-light-medium;
}
.mat-button-focus-overlay {
	background-color:transparent !important;
}
.mat-mdc-progress-spinner {
	--mdc-circular-progress-active-indicator-color: $interface-color-light-medium !important;
	circle {
		stroke: $interface-color-light-medium !important;
	}
}
.sproutvideo-playlist{
	width: 100%;
}
.content:not(.mat-drawer-content){
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;

	.spinner {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-self: center;
	}
}
.bumping-tiles {
	font-size: 1.6837vw;
	border: none;
	position: absolute;
	text-align: center;
	font-weight: bolder;
	border-radius: 4px;
	display: inline-block;
	align-items: center;
	justify-content: center;
	font-stretch: normal;
	line-height: 60px;
	padding-right: 8px;
	padding-left: 8px;
	min-width: 60px;
	min-height: 60px;
	border: solid 1px rgba(72, 30, 159, 0.24);
	// margin-left: 5px;
	// margin-right: 5px;

	&.static-block {
		transition: all 0.3s ease-out;
	}
}
.bumping-tiles-flashcards {
	font-size: 1.6837vw;
	border: none;
	position: absolute;
	text-align: center;
	font-weight: bolder;
	border-radius: 4px;
	display: inline-block;
	align-items: center;
	justify-content: center;
	font-stretch: normal;
	line-height: 260px;
	padding-right: 10px;
	padding-left: 10px;
	min-width: 60px;
	height: 792px;
	border: solid 1px rgba(72, 30, 159, 0.24);
	margin-left: 5px;
	margin-right: 5px;
	&.static-block {
		transition: all 0.3s ease-out;
	}
}

.drawerContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.btn-default {
	background-color: $interface-color-light-medium;
	font-size: smaller;
	font-weight: bold;
	color: white;
	width: 100%;
}
.plan-description-header {
	width: 100%;
	text-align: center;
	padding: 12px 0;
	margin-bottom: 10px;
	background-color: #F7FAFB;
	font-weight: bold;
	color: rgba($primary-dark, 0.56);
	font-size: 19px;
	color: rgb(109, 118, 127);
	border-bottom: 1px solid rgb(234, 236, 238);
		.dark-theme & {
			background-color: transparent !important;
		}
}
.whizzimo-link {
	color: $interface-color-light-medium;
	cursor: pointer;
}

.ellipsis-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: auto;
	max-width: 300px;
	display: inline-block;
}
.button-selected{
	@include fill-button;
	font-style: italic;
	letter-spacing: 0.4px;
	text-transform: capitalize !important;
	background-color: white  !important;
	border: 2px solid #866dff !important;
	border-radius: 4px;
	.mdc-button__label{
		color: #866dff !important;
	}
}

.fileContainer {
	overflow: hidden;
	position: relative;
	cursor: pointer;
	@include fill-button;
	box-shadow: none !important;
	line-height: 1.5em;
	color: white;
	height: 35px;
	padding: 5px;
}
.inactive-fileContainer {
	overflow: hidden;
	position: relative;
	cursor: pointer;
	@include transparent-button;
	box-shadow: none !important;
	line-height: 1.5em;
	color: rgba(0, 0, 0, 0.87);
	height: 35px;
	padding: 5px;
}

.fileContainer [type=file] {
	cursor: inherit;
	display: block;
	font-size: 999px;
	filter: alpha(opacity=0);
	min-height: 100%;
	min-width: 100%;
	opacity: 0;
	position: absolute;
	right: 0;
	text-align: right;
	top: 0;
}  // border: solid 1px $whizzimo-modal-background;
.inactve-fileContainer [type=file] {
	cursor: inherit;
	display: block;
	font-size: 999px;
	filter: alpha(opacity=0);
	min-height: 100%;
	min-width: 100%;
	opacity: 0;
	position: absolute;
	right: 0;
	text-align: right;
	top: 0;
}  // border: solid 1px $whizzimo-modal-background;

.red-circle{
	width: 8px;
	height: 8px;
	background: #ff5a5a;
	border-radius: 50px;
	position: absolute;
	right: 4px;
	top:4px;
}
.x-small-tile{
	min-height: 30px !important;
	min-width: 30px !important;
	line-height: 30px !important;
	font-size: 0.9837vw !important;
}
.small-tile{
	min-height: 40px !important;
	min-width: 40px !important;
	line-height: 40px !important;
	font-size: 20px !important;
}
.medium-tile{
	min-height: 60px !important;
	min-width: 60px !important;
	line-height: 60px !important;
	font-size: 27.5px !important;


}
.large-tile{
	min-height: 80px !important;
	min-width: 80px !important;
	line-height: 80px !important;
	font-size: 32px !important;
}
.x-large-tile{
	min-height: 100px !important;
	min-width: 100px !important;
	line-height: 100px !important;
	font-size: 40px !important;


}
.xx-large-tile{
	min-height: 120px !important;
	min-width: 120px !important;
	line-height: 120px !important;
	font-size: 3.4837vw !important;


}

.xxx-large-tile{
	min-height: 140px !important;
	min-width: 140px !important;
	line-height: 140px !important;
	font-size: 4.0837vw !important;


}

.mat-radio-checked{
	.mat-radio-outer-circle{
		border-color: $interface-color-light;
	}
	.mat-radio-inner-circle{
		background-color: $interface-color-light;
	}


}
.shadow {
	border: solid 1px rgba(134, 128, 145, 0.24);
	border-radius: 4px;
	min-width: 60px;
	height: 60px !important;

}

.black-white-text {
	color: $whizzimo-black-white;
}

.purple-text {
	color: $whizzimo-purple !important;
}

.purple-checkbox{
	.mat-mdc-checkbox-checked{
		.mat-mdc-checkbox-layout{
			.mat-mdc-checkbox-inner-container{
			background: $whizzimo-purple !important;
			color: $interface-color-light !important;
			}
		}
	}
}

.purple-checkbox-option{
	.mat-pseudo-checkbox-checked{
		color: $whizzimo-purple !important;
	}
	.mat-pseudo-checkbox{
		color: $whizzimo-purple !important;
	}
}

.pairing-dropdown{
	padding-left: 20px !important;
}

.add-success-light{

	background-color: $interface-color-darkar  !important;
}
.add-success-dark{

	background-color: $interface-color-darkar !important;
}
.no-border {
border: none !important;
}

.blenderbox-target {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;

    .selected-line {
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      background: #888;
      height: 6px;
      width: 50px;
      transition: all 0.2s ease;
      // border: 1px solid black;
    }

    .selected-line::before {
      content: "";
      position: absolute;
      top: -0.625rem; /* Adjust to extend clickable area above and below */
      left: -0.875rem; /* Adjust to extend clickable area to the left and right */
      right: -0.875rem;
      bottom: -1.35rem;
      background: transparent;
      // border: 1px solid green;
      cursor: pointer;
    }

    // [ngStyle]="{ 'width': box.width / 2 + 'px', 'height': '6px', 'background': '#888', 'position': 'absolute', 'bottom': '-16px', 'left': '50%', 'transform': 'translateX(-50%)' }"

    .line-arrow {
      /* Add arrowhead at the end of the line */
      transition: all 0.2s ease;
      position: absolute;
      background: #888;
    }

    .line-arrow::after {
      content: "";
      position: absolute;
      right: -12px;
      /* Adjust to place arrowhead at the end of the line */
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #888;
    }
  }

	//global css//

	/*= All Possible Tile Colors
	========================================*/
.c-red{
	background: $grapefruit !important;
}
.c-yellow{
	background: $light-mustard !important;
}
.c-peach{
	background: $light-peach !important;
}
.c-lightyellow{
	background: $light-beige !important;
}
.c-lightgreen{
	background: $very-pale-green !important;
}
.c-green{
	background: $soft-green !important;
}
.c-blue{
	background: $soft-blue !important;
}
.c-lightblue{
	background: $pale-sky-blue !important;
}
.c-purple{
	background: $periwinkle !important;
}
.c-lightpurple{
	background: $pale-lilac !important;
}
.c-orange{
	background: $orangeish !important;
}
.c-gray{
	background: $battleship-grey !important;
}
.c-pink{
	background: $bubblegum !important;
}
.c-cream{
	background: $off-white !important;
}

.c-transparent {
	background: $c-transparent !important;
	box-shadow: none !important;
	border: none !important;
}

.c-clear {
	background: $c-clear;
	box-shadow: none !important;
	border: none !important;
	display: none;
	z-index: 0 !important;
	visibility: hidden;
}
/*= All Possible Tile Colors
========================================*/

.more_words_modal_activity, .more_tiles_modal_activity{
	color: $interface-color-light-medium;
	margin-bottom: 20px;
	cursor: pointer;
	margin-top: 12px;
	text-transform: uppercase;
	display: block;
	font-size: 14px;
}
.global-wrap {
	height: 100%;
	width: 100%;
	margin-bottom:85px;
}

/* ===setting header ===*/

.setting_header_div{
	background: $primary-dark-background-color;
	padding: 0px 24px;
	display: flex;
	position: sticky;
	top: 0;
	z-index: 10;

	.setting_header_div_left{
		width: 50%;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;

		.help_icon{
			position: relative;
			top: 4px;
			left: 10px;
		}
		div{
			display: flex;
			align-items: center;
			h2{
				@include h1-header;
				margin: 0;
				@media only screen and (max-width: $tabView){
					font-size: 22px !important;
				}
			}
		}

	}
	.account_setting_header_div_left{
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		h2{
			color: $primary-dark;
		}
	}
	.header-workbook-name {
		font-size: 14px;
		color: $interface-color-light-medium;
		cursor: pointer;
		margin: 0px 0px 7px;
		align-items: center;
		text-transform: uppercase;
		outline: none;
		font-weight: 500;
	}

	.help_icon {
		cursor: pointer;
	}
	.setting_header_div_right{
		width: 50%;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.setting-type-dropdown {
			float: none !important;
			margin-top: 25px;
			justify-content: flex-end !important;

			.mat-mdc-form-field-infix {
				width: 276px;
				padding: 0 16px !important;
				color: $primary-dark;
				font-size: 16px;
				.mat-select-value-text {
					color: $primary-dark;
					font-size: 16px;
				}
			}

			.mat-mdc-form-field-label {
				top: 33px;
				left: 16px;
				color: $primary-dark;
				font-size: 16px;
			}
			&.mat-mdc-form-field-should-float {
				.mat-mdc-form-field-label {
					font-size: 13px;
					background-color: $primary-dark-background-color;
					padding: 5px 10px;
					width: auto;
					top: 21px;
					left: 16px;
				}
			}
		}
	}
}

/* ===setting header ===*/

/* === General setting page ===*/

.mat-mdc-option {
	.mdc-list-item__primary-text {
		color: $primary-dark;
		font-size: 14px !important;
		letter-spacing: normal;
		img {
			width: 24px;
			position: relative;
			float: right;
		}
	}
	// &:hover {
	// 	background-color: transparent !important;

	// 	.mat-option-text {
	// 		color: $interface-color-light-medium;
	// 	}
	// }
	// &.mat-active {
	// 	background-color: transparent !important;
	// 	.mat-option-text {
	// 		color: $interface-color-light-medium;
	// 	}
	// }
}
.mat-option-disabled{
	.mat-option-text {
		color: #dfdee5;
		font-size: 14px;
		img {
			width: 24px;
			position: relative;
			top: 12px;
			float: right;
		}
	}
}
.setting_screens{
	.content_div{
		display: flex;
		flex-direction: column;
		background: $primary-dark-background-color;
		margin: 24px;
		border-radius: 8px;

		.each_row{
		display:flex;
		height:100%;
		border-bottom:1px solid rgba($primary-dark, 0.08);
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 24px 24px;

		.dark-theme & {
			color: white;
		}

		&:last-child{border-bottom:none}

		.description-section{
			width: 40%;

			h3{
				@include h2-header;
				line-height: 1;
				margin: 0;
				margin-bottom: 8px;
			}
			p{
				@include paragraph;
				line-height: 1.25;
				margin: 0;
			}

		}
		.description_option{
			width: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: flex-start;

			p{
				color: rgba($whizzimo-black-white, 0.87);
			}
			.mat-select-value {
				color: rgba($whizzimo-black-white, 0.87);
			}
			.setting_radio{
				width: 100%;

				.mat-radio-label{
					white-space: normal;
				}
				.mat-radio-ripple{
					color: $primary-dark;
					font-size: 16px;
				}
			}
			.mat-radio-outer-circle{
				border-color: rgba($whizzimo-black-white, 0.54);
			}
			.mat-radio-checked{
				.mat-radio-outer-circle{
					border-color: $interface-color-light;
				}
				.mat-radio-inner-circle{
					background-color: $interface-color-light;
				}
			}
			.words_input{
				width: 90px;
				display: inline-block;
				margin-top: 16px;
			}
			.label{
				font-size: 16px;
				color: rgba($primary-dark, 0.56);
				margin-left: 20px;
			}
			.flascard_radio{
				width: 100%;
				display: block;
				margin: 0 0 18px;
				.mat-radio-ripple {
					margin: 0 0 8px;
				}
				p{
					color: rgba($whizzimo-font-light, 0.56);
					margin: 8px 29px 4px;
				}
			}

		}

		}

		.custom_row{
			align-items: flex-start !important;
		}

		.colorblock_radio{
			.colorblock_radio-button{
					margin-right: 4px;
					border: 1px solid transparent;
					.mdc-radio__background{opacity:0;display: none;}
					.mat-radio-ripple{
						background: $interface-color-light-soft;
						height: 40px;
						width: 40px;
						margin: 2px;
						padding: 0;
						-webkit-border-radius: 5px 5px 5px 5px;
						border-radius: 5px 5px 5px 5px;
					}
					&.mat-radio-checked{
						display: inline-block;
						height: 44px;
						border: solid 2px rgba($whizzimo-black-white, 0.5);
						border-radius: 5px;
					}
					&.mat-radio-not-checked{
						border: solid 1px #ffffff;
						border-radius: 4px;

					}
					&.gray{
						.mat-radio-ripple{
							background: $interface-color-light-soft;
						}
					}
					&.black{
						.mat-radio-ripple{
							background: #000000;
						}
					}
					&.white{
						.mat-radio-ripple{
							background: #fff;
							border: 1px solid $whizzimo-border-transparent;
						}
					}
					&.palesky{
						.mat-radio-ripple{
							background: #bde7ff;
						}
					}
					&.verypalegreen{
						.mat-radio-ripple{
							background: #d6f7be;
						}
					}
					&.light-beige{
						.mat-radio-ripple{
							background: #fff6b7;
						}
					}
					&.offwhite{
						.mat-radio-ripple{
							background: #fbf5e5;
						}
					}
					&.lightpink{
						.mat-radio-ripple{
							background: #ffdaec;
						}
					}
					&.softblue{
						.mat-radio-ripple{
							background: #568aff;
						}
					}
					&.grapefruit{
						.mat-radio-ripple{
							background: #ff5a5a;
						}
					}
					&.light-mustard{
						.mat-radio-ripple{
							background: #ffca5a;
						}
					}
					&.soft-green{
						.mat-radio-ripple{
							background: #6dd378;
						}
					}
					&.periwinkle{
						.mat-radio-ripple{
							background: #8e70ff;
						}
					}
					&.orangeish{
						.mat-radio-ripple{
							background: #ff8e42;
						}
					}
					&.bubblegum{
						.mat-radio-ripple{
							background: #ff76b8;
						}
					}
				}
		}
	}
	.mat-mdc-form-field-infix{
		display: block;
		position: relative;
		flex: auto;
		min-width: 0;
		width: 276px;
		height: 56px;
		display: flex;
		align-items: center;
		color: $primary-dark;
	}
	.mat-mdc-form-field-underline{display:none}
	.mat-mdc-form-field-appearance-legacy{
		.mat-mdc-form-field-wrapper{padding-bottom: 0;}
	}

	.mat-mdc-tab-group{
		.mat-mdc-tab-body-content{
			height: auto;
		}

		.mat-mdc-tab-header {
			display: flex;
			overflow: hidden;
			position: sticky;
			flex-shrink: 0;
			top: 0px;
			z-index: 9;
			background-color: #ffffff;
		}
		.mat-mdc-tab-body {
			display: flex;
			width: 100%;
			flex-direction: column;
			background: $primary-dark-background;
			margin: 0px;
			// padding: 15px;

			.tab_title {
				// margin: 22px 18px 40px;
				margin:0;
				padding:22px 18px 40px;
				// width: 58%;
				display: block;
				font-size: 16px;
				line-height: 1.25;
				color: $whizzimo-font-light;
			}
			.tab_title_compound {
				margin: 22px 0px 40px;
				width: 100%;
				display: block;
				font-size: 16px;
				line-height: 1.25;
				color: $whizzimo-font-light;
			}
			.long-card {
				margin: 0px;
				background-color: transparent;
			}

			.mat-mdc-card{
				box-shadow: none !important;
				background-color: $primary-dark-background-color;

				.style-card-header {
					width: 100%;
					height: 30px;
					background-color: transparent;
					color: $primary-dark;
					font-weight: bold;
					padding: 20px 0px 0px 0px !important;
					word-wrap: break-word;
					text-align: center;
				}
				.tile_bgcolor{
					background: transparent !important;
					display: flex;
					justify-content: center;

					.style-card-type {
						margin: 30px;
						font-size: larger;
						text-align: center;
						font-weight: bolder;
						border-radius: 5px;
						width: 76px;
						border: none;
						padding: 13px 0px;
						border: none;
						font-size: 32px;
						height: 38px;
					}

				}

				.mat-mdc-form-field-infix{
					width: 150px;

					.mat-mdc-form-field-label {
						font-size: 13px;
						background-color:  $primary-dark-background-color;
						padding: 5px 10px;
						width: auto;
						top: 21px;
						left: 16px;
						color: $primary-dark;
					}
					.mat-select-value{
						color: rgba($whizzimo-black-white,0.87);
					}
				}

				.style-card-type {
					margin: 20px auto;
					padding: 21px 0px;
					font-size: larger;
					text-align: center;
					font-weight: bolder;
					border-radius: 5px;
					width: 76px;
					height: 22px;
					border: none;
				}
				.compound_example{
					font-size: 16px;
					color: $primary-dark;
					font-weight: 600;
				}
			}

			.compound_mat_card{
				padding: 23px 0px;
				width: 76px;
				border-radius: 4px;
				line-height:18px;
				font-size: 1.6837vw !important;

			}
			.tile_layout_div{
				border-bottom: 1px solid $whizzimo-border-transparent;
				padding: 0px 0px 24px 0px;
				margin: 0px 20px !important;

				.mat-card{
					width: 76px;
					height: 20px;
					padding: 22px 0px;
					border-radius: 4px;
					text-align: center;
				}
			}
			.tile_layout{
				.mat-grid-list{
					border: none;
				}
				.mat-grid-tile{
					background-color: transparent !important;

					.mat-figure{
						div{
							width: 100% !important;
							height: 100% !important;
						}
					}
				}
			}

		}
	}
}

/* === Geberal setting page ===*/

/*======= side nav bar ===*/

.mat-drawer{
	width: 358px;
	background-color: $primary-dark-menu !important;

	.mat-drawer-inner-container{
		overflow: hidden;

		.side-container{
			max-width: 358px;
			.side-top{
				justify-content: start !important;
				padding: 31px 32px 0px;
				// height: 65px !important;

				img{
					border-radius: 5px;
				}
			}
			.side-middle{
				overflow:hidden !important;
				border-top: solid 1px rgba($interface-color-light-medium, 0.48);

				.mat-tree{
					width: 100%;
					background:transparent;
					// padding: 0px 24px 0px 32px;

					.mat-tree-node{
						color: #fff;
						font-size: 16px;
						position: relative;
						font-weight: bold;
						padding-left: 0px;

						&[aria-expanded="true"]{

							.icon_btn{

								&::before{
									-ms-transform: rotate(180deg); /* IE 9 */
									transform: rotate(180deg);
								}
							}

						}
						.mat-button{
							padding: 0px 0px;
							text-align: left;
						}


					}

					.icon_span_active{
						background-color: $whizzimo-menu-item-active;
						color: #fff;
						border-radius: 5px;
						.mdc-list-item__content {
							background-color: $whizzimo-menu-item-active !important;
							color: white;
							padding: 0px 47px;
							border-radius: 8px;
						}
					}

					.icon_span_inactive{
						opacity: 72%;
					}


					.custom-mat-expansion-panel {
						&::before{
							margin-left: 0px;
						}
					}

					.mat-expansion-panel{
						background-color: $primary-dark-menu;
						color: white !important;
						width: 100%;

						.mat-expansion-panel-header{
							background-color: $primary-dark-menu;
							color: white !important;
							padding-right: 60px;
							height: 18px !important;
							padding-left: 69px;
							.mat-expansion-indicator {
								position: relative;
								right: 1px;
							}
							.mat-expansion-panel-header-title{
								font-weight: 600;
								font-size: 16px;
								display: flex;
								align-items: center;
								margin-left: 15px;
								cursor: pointer;
							}
						}

						.mat-expansion-panel-body{
							background-color: $primary-dark-menu;
							color: white !important;
							// padding-top: 20px;
							padding: 0;
							.mdc-list-item__content{
								background-color: $primary-dark-menu;
								color: $whizzimo-menu-content;
								padding: 0px;
								padding-left: 48px;
								.mdc-list-item__primary-text {
									display: flex;
									flex-direction: row;
									align-items: end;
									color: white;
									overflow: initial;
									letter-spacing: normal;
									.focus-theme & {
										color: $primary-color !important;
									}
								}
							}
						}

						.mat-expansion-indicator::after {
							color: $whizzimo-menu-content !important;
						}

						.mat-expanded{
							background-color: $primary-dark-menu;
							color: white !important;
							height: 18px !important;
						}

						// &.is-sharing-active {
						// 	background-color: #481e9f;
						// 	mat-expansion-panel-header  {
						// 		background-color: #481e9f;
						// 		mat-panel-title  {
						// 			background-color: #481e9f;
						// 		}
						// 	}
						// 	.dark-theme & {
						// 		background-color: #291f4f !important;
						// 	  mat-expansion-panel-header  {
						// 			background-color: #291f4f !important;
						// 			mat-panel-title  {
						// 				background-color: #291f4f !important;
						// 			}
						// 		}
						// 	}
						// }

					}

					.mat-content{
						color: white;
						opacity: 1;
						.mat-expansion-panel-header-title {
							background-color: $primary-dark-menu;
							color: $whizzimo-menu-content;
						}
					}



				}
				.ng-scrollbar-visible{
					right: -9px !important;
				}
			}
			.side-bottom{
				// width:100%;
				justify-content: start !important;
				border-top: solid 1px rgba($interface-color-light-medium, 0.48);
				padding: 11px 32px !important;
				height: 72px !important;

				.user-icon{
					//background-color: $interface-color-light-medium;
					border-radius: 50px;
					padding: 12px 13px;
					color: $whizzimo-menu-content;
					margin-right: 10px;
					opacity: 0.32;
				}
				.user-text{
					align-self: flex-end !important;

					h3{
						font-size: 16px;
						font-weight: bold;
						color: $secondary-color;
						line-height:1px;
					}
					button{
						background: transparent;
						border: transparent;
						box-shadow: none;
						font-size: 14px;
						font-weight: 500;
						color: $interface-color-light-medium;
						text-transform: uppercase;
					}
				}
				.name-text {
					color: $whizzimo-menu-content;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					// width: 200px;
					display: inline-block;
					font-weight: 600;
					// text-align: center;
					font-size: 16px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1;
					letter-spacing: normal;

					}
			}

			.active_account{
				.user-icon{
					background-color: $active-account-color !important;
					color: $primary-dark !important;
				}
				.user-text{
					button{
						color: $active-account-color !important;
					}
				}
			}
		}
	}
}

/*======= side nav bar ===*/

/*======= activity page header ===*/

.activity_header_custom {
	width: 100%;

	.mdc-button,
	.mat-mdc-button,
	.mat-mdc-button-base {
		padding: 0 16px !important;
		height: inherit;
	}

	.mat-mdc-button>.mat-icon {
		color: #fff;
		height: 24px !important;
		width: 24px !important;
		font-size: 24px !important;
	}

	.activity-header{
		background-color: $primary-dark-background-color !important;
		box-shadow: none !important;
		// position: absolute !important;
		width: 100% !important;
		z-index: 114;
		height: 40px;
		top:85px;

		.tool_btn_custom{
			position: relative;
			min-width: unset;
			// height: 30px !important;
			// min-width: 35px;
			display: block;
			// margin-left: 1%;
			background-color: transparent;
			height: 100%;
			@media only screen and (max-width: $tabView){
				min-width: 50px;
			}

			&::before{
				content: "";
				// position: absolute;
				width: 24px;
				height: 24px;
				display: block;
				background-repeat: no-repeat;
				// top: 3px;
				// left: 16.5%;
			}
		}
		.cdk-program-focused{
			display: none;
		}
		.up{
			&::before {
				background-image: url($down-icon);

			}

		}
		.down{
			background-color: $read-button-active-bg;

			&::before {
				// background-image: url($up-icon);
		background-image: url("assets/img/up.png");

			}
		}
		.settings-button{
			&::before {
				width: 22px !important;
				height: 22px !important;
				// // top: 0px !important;
				// // left: 17px !important;
				// position: relative;
				// top: -3px;
				// right: 3px;
				background-image: url($settings-icon);
				background-size: contain;
			}
		}

		.settings-dropdown-container {
			position: absolute;
			// top: 0; // Adjust based on your button position
			right: 10px;
			background-color: $primary-dark-background-color;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
			z-index: 1000;
			border-radius: 8px;
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
			padding: 8px;
			padding-top: 12px;
			width: 300px;
		  }

		.blendmode-button {
			&::before {
				background-image: url($blender-icon);
				background-size: contain;
			}
		}
		
		.active_blendmode{
			background-color: $read-button-active-bg;

			&::before {
				background-image: url($blender-icon-active) !important;
			}
		}

		.rotate {
			color: $rotate;
		}

		.read-button{
			&::before{
				background-image: url($read-button);
			}
		}
		.active_read{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($read-button-active) !important;
			}
		}
		.folder-button{

			&::before{
				background-image: url($folder-button);
			}
		}
		.active_folder{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($read-button-active) !important;
			}
		}

		.edit-button{

			&::before{
				background-image: url($edit-button);
			}
		}

		.active_btn {
			background-color: $read-button-active-bg;
		}

		.active_draw{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($edit-button-active) !important;
			}
		}
		.eraser-button{

			&::before{
				background-image: url($erase-button);
				top: 5px !important;
			}
		}
		.active_eraser{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($erase-button-active) !important;
			}
		}
		.color-button{
			// margin-left: 10%;
		}
		.active_color{
			background-color: $read-button-active-bg;

			// &::before{
			// 	background-image: url($color-button-active) !important;
			// }
		}

		.undo-button{

			&::before{
				background-image: url($undo-button);
			}
		}
		.active_undo{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($undo-button-active) !important;
			}
		}
		.delete-button{

			&::before{
				background-image: url($delete-button);
			}
		}
		.active_delete{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($delete-button-active) !important;
			}
		}
		.visibility-button{

			&::before{
				background-image: url($visibility-button);
			}
		}
		.active_visibility{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($visibility-button-active) !important;
			}
		}

		.visibility_hidden{
			background-color: $read-button-active-bg;
			&::before{
				background-image: url($visibility-hidden) !important;
			}
		}
		.keyboard-button{

			&::before{
				background-image: url($keyboard-button);
			}
		}
		.active_keyboard{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($keyboard-button-active) !important;
			}
		}
		.assignment-button{

			&::before{
				background-image: url($assignment-button);
			}
		}
		.active_assignment{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($assignment-button-active) !important;
			}
		}
		.format_size-button{

			&::before{
				background-image: url($format-size-button);
			}
		}
		.active_format_size{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($format-size-button-active) !important;
			}
		}
		.aspect_ratio-button{

			&::before{
				background-image: url($aspect-ratio-button);
			}
		}
		.active_aspect_ratio{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($aspect-ratio-button-active) !important;
			}
		}
		.search-plus-button{

			&::before{
				background-image: url($search-plus);
			}
		}
		.active_search_plus{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($search-plus-active) !important;
			}
		}
		.search-minus-button{

			&::before{
				background-image: url($search-minus);
			}
		}
		.active_search_minus{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($search-minus-active) !important;
				background-repeat: no-repeat;
			}
		}
		.landscape-button{
			&::before{
				background-image: url($landscape-button);
			}
		}
		.active_landscape{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($landscape-button-active) !important;
				background-repeat: no-repeat;
			}
		}
		.bumping-tiles-button{
			&::before{
				background-image: url($bumping-tiles-button);
			}
		}
		.active_bumping_tiles{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($bumping-tiles-button-active) !important;
				background-repeat: no-repeat;
			}
		}
		.rotate-canvas-button{
			&::before {
		appearance:none;
		background-image: none;
			}
		}
		.active_rotate_canvas {

		}
		.active_pdf_search_minus{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($search-minus-active) !important;
				background-repeat: no-repeat;
			}
		}
		.view_array-button{

			&::before{
				background-image: url($view-array-button);
			}
		}
		.active_view_array{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($view-array-button-active) !important;
				background-repeat: no-repeat;
			}
		}

		.info-button{

			&::before{
				width: 27px !important;
				height: 26px !important;
				background-image: url($info-button);
				// top: 0px !important;
				// left: 17px !important;
				position: relative;
				top: -3px;
				right: 3px;
			}
		}
		.active_info{
			background-color: $read-button-active-bg;

			&::before{
				background-image: url($info-button-active) !important;
			}
		}

		.color-button{

			.mdc-button__label{
				// position: absolute;
				// top: -3px;
				// left: 16.5%;
				position: relative;
				bottom: 22px;
			}
		}


		.activity_dropdown{
			position: absolute !important;
			left: 21px;
			top: 0;
			.dropdown_div{
				position: relative;
				height: 27px;
				display: inline-flex;
				align-items: center;
				margin-bottom: 0px;
				margin-top: 12px;
				top: -12px;

				.top_dropdown{
					font-size: 14px;
					color: $interface-color-light-medium;
					position: relative;
					height: 30px;
					align-items: center;
					display: flex;
				}

				button{
					background: transparent;
					border: none;
					outline: none;
					box-shadow: none;
					height: 16px;
					position: relative;
					top: -3px;
					cursor: pointer;

					img{
						height: 16px;
					}
				}
			}

			.dropdown_child_div{
				position: relative;
				top: -35px;
				height: 32px;
				display: flex;
				align-items: center;

				span{
					font-size: 32px;
					font-weight: bold;
					color: $primary-dark;
					height: 50px;
					display: flex;
					align-items: center;
					line-height: 0px;
				}
				button{
					background: transparent;
					border: none;
					outline: none;
					box-shadow: none;
					cursor: pointer;
				}
			}


			/*.mat-mdc-form-field-wrapper{
				padding-bottom: 10px !important;
			}
			.mat-select-value-text{
				font-size: 32px;
				color: #210854;
				font-weight: bold;
			}
			.mat-mdc-form-field-underline{
				background-color: transparent;
			}*/
		}

		.visibility-button{
			.mdc-button__label{
				position: absolute;
				top: -3px;
				left: 19px;
			}
		}
		.active-indicator{
			width: 100%;
			height: 5px;
			position:relative;
			background-color: $primary-dark;
		}
	}
	.pad-container{
		box-shadow: $pad-box-shadow;
		.pullout-handle{
			z-index: 120;
			width: 40px;
			height: 40px;
			border: solid 1px rgba($primary-dark, 0.08);
			background-color: $whizzimo-light;
			border-radius: 50%;
			position: relative;
			background-image: none;
			margin: auto;
			opacity: 0;
			&::before{
				content: "";
				display: block;
				width: 24px;
				height: 24px;
				position: absolute;
				top: 8px;
				left: 7px;
			}
		}
		.up{
			background-image: url($down-icon);
			background-position: center;

		}
		.down{
			// background-image: url($up-icon);
	background-image: url("assets/img/up.png");
			background-position: center;
		}
		.pullout-handle-up{
			z-index: 116;
			width: 40px;
			height: 40px;
			border: solid 1px rgba($primary-dark, 0.08);
			background-color: #ffffff;
			border-radius: 50%;
			position: relative;
			bottom: -22px;
			background-image: none;
			margin: auto;
			// top: 85vh;

			&::before{
				content: "";
				display: block;
				width: 24px;
				// left: 50%;
				height: 24px;
				position: absolute;
				top: 8px;
				left: 7px;
			}
		}
	}
}

/*======= activity page header ===*/

/*======= blackboard page ===*/
.new-tile-container {
	overflow: hidden;
}
.blackboard_custom{
	// margin-top:180px;
	// height:66%;
	@media only screen and (min-height: 2160px){
			height: 91%;
	}
	@media only screen and (min-height: 1440px) and (max-height: 2160px){
			height: 86%;
	}
	@media only screen and (min-height: 1200px) and (max-height: 1440px){
			height: 86%;
	}
	@media only screen and (min-height: 1080px) and (max-height: 1200px){
			height: 82%;
	}
	@media only screen and (min-height: 1024px) and (max-height: 1080px){
		height: 80%;
	}
	@media only screen and (min-height: 900px) and (max-height: 1024px){
			height: 78%;
	}
	// @media only screen and (min-height: 768px) and (max-height: 900px){
	// 		height: 75%;
	// }
	@media only screen and (min-height: 700px) and (max-height: 768px){
		height: 74%;
	}
	.new-tile-container {
		height: 100%;
		background-color: $whizzimo-blackboard-body;
	}
	.mat-figure	{
		background-color: transparent;
		.inside-tile{
			width: 100% !important;
			height: 100% !important;
			border-radius: 0px !important;
		}
	}

	.line-holder{

		.line{
			border-bottom: 1px solidrgba($primary-dark, 0.16);
		}
	}
	.frozen-row-shadow{
		-webkit-box-shadow: $frozen-row-shadow;
		-moz-box-shadow: $frozen-row-shadow;
			box-shadow: $frozen-row-shadow;
		//  height: 6vh !important;
			background: $interface-color-light-soft;
		}
}

/*======= blackboard page ===*/

/*======= sentence page ===*/

.sentence_div{
	// height: 100% !important;
	margin: 0px 0px;

	.sentence_mat_card{
		height: 433px !important;
		padding: 179px 0px !important;
		border-radius: 8px !important;
		box-shadow: $box-defult-shadow !important;
		background-color: #ffffff !important;
		text-align: center;
		width: 100%;

		span{
			font-size: 64px;
			color: $primary-dark;
			font-weight: bold;
		}
	}
}

.sentence_tally{
	top: 10px !important;
	padding: 10px 10px;
	position: relative !important;
	display: flex;
	justify-content: center;

}

/*======= sentence page ===*/

/*======= PDF page ===*/
.pdf-container {
	display: flex;
	margin-top: 180px;

	.pdf-navigators {
		z-index: 111;
	  display: flex;
	  justify-content: center;
	//   align-items: flex-start;
	  width: 125px;
	  height: 150px;
	  position: relative;
	  top: 35vh;
	}
	.blackboard-canvas {
		position: absolute;
		z-index: 110;
		// height: 3000px;
		// width: 2000px;
		overflow: hidden;
	  }


	.pdf-screen {
	  flex: 0;
	  align-items: center;
	//   background-color: $body-background;
	  border: none;
	  box-shadow: none;
	  width: 70vw;
	}
  }

.pdf_control_custom{
	justify-content: space-between !important;
	z-index: 111;
	.control_left{
		.mat-mdc-form-field-infix{
			display: block;
			position: relative;
			flex: auto;
			min-width: 0;
			width: 375px;
			height: 56px;
			border-radius: 4px;
			border: solid 1px rgba($primary-dark, 0.32);
			padding: 0 24px !important;
			display: flex;
			align-items: center;
			color: $primary-dark;
		}
		.mat-mdc-form-field-underline{display:none}
		.mat-mdc-form-field-appearance-legacy{
			.mat-mdc-form-field-wrapper{
				padding-bottom: 0;
			}
		}
		.mat-mdc-form-field-label {
			top: 35px;
			left: 16px;
			color: $primary-dark;
			font-size: 16px;
			background-color: $interface-color-light-soft;
			padding: 5px 10px;
		}
		&.mat-mdc-form-field-should-float {
			.mat-mdc-form-field-label {
				font-size: 13px;
				background-color: $interface-color-light-soft;
				padding: 5px 10px;
				width: auto;
				top: 20px;
				left: 16px;
			}
		}
	}

	.control_right{
		.mat-mdc-form-field-flex{
			width: 40px;
		}
		span{
			margin-left: 25px;
		}

		.mat-mdc-form-field-infix{
			display: block;
			position: relative;
			flex: auto;
			min-width: 59px;
			width: 100px;
			height: 56px;
			border-radius: 4px;
			border: solid 1px rgba($primary-dark, 0.32);
			padding: 0 0px !important;
			padding-top: 10px;
			display: flex;
			align-items: center;
			color: $primary-dark;

			input{
				text-align: center !important;
			}
		}
		.mat-mdc-form-field-underline{display:none}
		.mat-mdc-form-field-appearance-legacy{
		.mat-mdc-form-field-wrapper{}
		}
		.mat-mdc-form-field-label-wrapper {
			left: -70px;
			top: 19px;
			.mat-mdc-form-field-label{
				font-size: 16px;
				color: $primary-dark;
			}
		}

	}
}
#overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 101 !important;
	opacity: .4;
	pdf-viewer {
		z-index: 0 !important;
		position: absolute;
		height: 100%;
		width: 100%;

		&.hidden {
			opacity: 0;
		}
		}

}

.pdf_div{
	display: flex !important;
	flex-direction: row;
	justify-content: space-between;
	height: auto !important;

	.back {
		display: flex;
		flex-direction: column;
	}
	.next {
		display: flex;
		flex-direction: column;

	}

	.pdf_mat_card{
		height: 100% !important;
		border-radius: 8px !important;
		box-shadow: 0 4px 16px 0 rgba($primary-dark, 0.08) !important;
		background-color: #ffffff !important;
		text-align: center;
		width: 100%;
	}
}

/*======= PDF page ===*/

/*======= Tile layout ===*/

.tile-content_div{
	.cdk-drag-placeholder {
		opacity: 0;
		display: none;
		}

	.mat-figure{
		background-color: transparent;
		.inside-tile{
			width: 100%;
			height: 100%;
			border-radius: 0px !important;
		}
	}
}
.tile-lay-out-bottom {
	background-color: $primary-dark-background-color;
}

.tilelayout_div_footer{
	background-color: $primary-dark-background-color;
	.mat-card-content {
		font-size: 1.6837vw !important;

	}
	.mat-card {
		min-width: 49px;
		min-height: 60px;
		padding:0px;
		border-radius: 4px;
		text-align: center;
		box-shadow: none;
		font-weight: bolder;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 6px;
		padding-right: 6px;
	}
}

/*======= Tile layout ===*/

/*======= activity header resizable handle  ===*/

.ng-resizable-handle{
	background-color: $interface-color-light-medium !important;
	width: 40px !important;
	height: 40px !important;

	&::before{
		content: "";
		position: absolute;
		width: 24px;
		height: 24px;
		background-image: url($resizable-handle-icon);
		background-repeat: no-repeat;
		display: block;
		top: 15px;
		left: 8px;
	}

	&::after{
		content: "";
		position: absolute;
		width: 24px;
		height: 24px;
		background-image: url($resizable-handle-icon);
		background-repeat: no-repeat;
		display: block;
		top: 2px;
		left: 8px;
		-ms-transform: rotate(180deg); /* IE 9 */
		transform: rotate(180deg);
	}
}

/*======= activity header resizable handle  ===*/

/*======= whiteboard page  ===*/

.whiteboard-container{
	height: 100%;
	width: 91%;
	position: absolute;
	left: 0;
	overflow: hidden;
	z-index: 0;
	.wordcard{
		color: $primary-dark;
		background-color: $primary-dark-background-color;
	}
}

/*======= whiteboard page  ===*/

/*======= phrases page  ===*/

.phrases_div{
	margin: 50px 0px;
	height: 100% !important;
}
.phrases_tally{
	padding: 10px 10px;
	position: relative !important;
	display: flex;
	justify-content: center;
}

.tally-box{
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative !important;
	top: 50px !important;
	height: auto !important;
	z-index: 1;
}

/*======= phrases page  ===*/

/*======= Spelling page  ===*/

.spelling_div{
	// margin: 90px 0px 0px;

	.new-tile-container{


		// .inside-tile{
		// 	width: 100%;
		// 	height: 100%;
		// }
	}

	.spelling_footer_div{
		background-color: $primary-dark-background-color;
		display: flex;
		flex-direction: column;
		.spelling_span_words{
			// position: relative;
			margin-left: 76px;
			// top: 10px;
			font-size: 16px;
			font-weight: 600;
			color: #bcbdbf;
			display: block;
			height: 32px;
		}
		.spelling_btn{
			// position: absolute;
			// top: 58px;
			// right: 32px;
			background: #ffb5a0;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: bold;
			color: $primary-dark;
			// @include fill-button;
		}
		.skip_btn{
			// position: absolute;
			// top: 58px;
			// right: 32px;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: bold;
			background: #eeeeee;
			color: #888888;
			max-width: 75px;
			// @include fill-button;
		}

		.spelling_icon{
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: 40px;
			margin-right: 32px;
			i{
				color: $interface-color-light-medium;
			}
		}
	}

}

/*======= Spelling page  ===*/

/*======= workbook page  ===*/

.workbook_tile_div{
	margin-top:180px;
	height:66%;
	@media only screen and (min-height: 2160px){
			height: 91%;
	}
	@media only screen and (min-height: 1440px) and (max-height: 2160px){
			height: 86%;
	}
	@media only screen and (min-height: 1200px) and (max-height: 1440px){
			height: 86%;
	}
	@media only screen and (min-height: 1080px) and (max-height: 1200px){
			height: 82%;
	}
	@media only screen and (min-height: 1024px) and (max-height: 1080px){
		height: 80%;
	}
	@media only screen and (min-height: 900px) and (max-height: 1024px){
			height: 78%;
	}
	@media only screen and (min-height: 768px) and (max-height: 900px){
			height: 75%;
	}
	@media only screen and (min-height: 700px) and (max-height: 768px){
		height: 74%;
	}

	position: relative;
	background-color: $primary-dark-background-color;
	.mat-figure	{
		background-color: transparent;
		.inside-tile{
			width: 100% !important;
			height: 100% !important;
			border-radius: 0px !important;
		}
	}
	// .bumping-tiles{
	// 	border: none;
	// 	padding: 16px;
	// }
	.line-holder{
		.line{
			border-bottom:1px solid rgba($primary-dark, 0.16);
		}
	}
	.dnd-drag-start{
		width: 50px;
		height: 50px;
	}
}


/*======= workbook page  ===*/

/*======= tilelayout page  ===*/


.tilelayout_div{

	.mat-figure{
		background-color: transparent;
		.inside-tile{
			width: 100%;
			height: 100%;
			border-radius: 0px !important;
		}
		&:empty {
			border: none !important;
		}
	}
}

/*======= tilelayout page  ===*/

/*======= flashcard page  ===*/

.flashcard-box{
	position: relative;
	.back{
		top: 45%;
		position: relative;
		height: 100px;
		.mat-button{

			@media only screen and (max-width: $extrasmalldesktopview){
				top: 0% !important;
			}
		}

	}
	.next{
		top: 45%;
		position: relative;
		height: 100px;
		.mat-button{

			@media only screen and (max-width: $extrasmalldesktopview){
				top: 0% !important;
			}
		}
	}
}
.state-box{
	position: relative;
	top: 57px;
	border-radius: 16px;
	border: solid 1px rgba($primary-dark, 0.16);
	background: transparent !important;
	font-size: 14px;
	color: $primary-dark !important;
}

/*======= flashcard page  ===*/

/*======= mini tile page  ===*/

.mini_tile{
	margin-top:180px;
	height:66%;
	@media only screen and (min-height: 2160px){
			height: 91%;
	}
	@media only screen and (min-height: 1440px) and (max-height: 2160px){
			height: 86%;
	}
	@media only screen and (min-height: 1200px) and (max-height: 1440px){
			height: 86%;
	}
	@media only screen and (min-height: 1080px) and (max-height: 1200px){
			height: 82%;
	}
	@media only screen and (min-height: 1024px) and (max-height: 1080px){
		height: 80%;
	}
	@media only screen and (min-height: 900px) and (max-height: 1024px){
			height: 78%;
	}
	@media only screen and (min-height: 768px) and (max-height: 900px){
			height: 75%;
	}
	@media only screen and (min-height: 700px) and (max-height: 768px){
		height: 74%;
	}

	// margin: 100px 0px;
	.center_align_tile {
		> div{
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			.new-tile-block{
				position:relative !important;
				left:0 !important;
				margin-right:10px;
				&:last-child{
					margin-right:0 !important;
				}
			}
		}

	}

	// .inside-tile{
	// 	width: 100%;
	// 	height: 100%;
	// }
	// .bumping-tiles{
	// 	padding-top: 16px;
	// 	border: none;
	// }
	.line-holder{

		.line{
			border-bottom:1px solid rgba($primary-dark, 0.16);
		}
	}
	.dnd-drag-start {
		width: 50px;
		height: 50px;
	}
}

/*======= mini tile page  ===*/

/*======= blank tile page  ===*/

.blank_tile{
	// margin-top:180px;
	// height:66%;
	@media only screen and (min-height: 2160px){
			height: 91vh;
	}
	@media only screen and (min-height: 1440px) and (max-height: 2160px){
			height: 86vh;
	}
	@media only screen and (min-height: 1200px) and (max-height: 1440px){
			height: 86vh;
	}
	@media only screen and (min-height: 1080px) and (max-height: 1200px){
			height: 82vh;
	}
	@media only screen and (min-height: 1024px) and (max-height: 1080px){
		height: 80vh;
	}
	@media only screen and (min-height: 900px) and (max-height: 1024px){
			height: 78vh;
	}
	@media only screen and (min-height: 768px) and (max-height: 900px){
			// height: 75vh;
	}
	@media only screen and (min-height: 700px) and (max-height: 768px){
		height: 72vh;
	}

	.center_align_tile {
		> div{
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			.new-tile-block{
				position:relative !important;
				left:0 !important;
				margin-right:10px;
				&:last-child{
					margin-right:0 !important;
				}
			}
		}

	}
}

/*======= mini tile page  ===*/

/*======= passage page  ===*/

.passage_tally{
	position: relative !important;
	display: flex;
	justify-content: center;
	height: 100px;
}
.passage_div{
	// height: 100% !important;
	// overflow-y: scroll !important;

	.passage_mat_card{
		padding: 68px 88px !important;
		height: 500px !important;
		overflow-y: scroll !important;
		border-radius: 8px !important;
		box-shadow: 0 4px 16px 0 rgba($primary-dark, 0.08) !important;
		background-color: #ffffff !important;
		text-align: center;
		flex-grow: 1;
		display: flex;
		justify-content: center;

		span{
			color: $primary-dark;
			font-size: 64px !important;
			font-weight: bold;
		}
	}
}

/*======= passage page  ===*/

/*======= time reading page  ===*/

.time_reading{
	margin: 0px 0px;

	.wordcards{
		font-size: 24px !important;
		color: $primary-dark;
	}
	.button-box{
		position: fixed;
		top: 40px;
		z-index: 117;
		right: 32px;

		.mat-mdc-button-base{
			@include time-reading-score-button;
			box-shadow: none;
		}
	}
	.wrong{
		text-decoration: line-through;
		color: #ff7463!important;
	}
}

.tile-container{
	width: 100% !important;
	position: relative;
	display: flex !important;
	flex-direction: row;
	padding-right: 8vw;
	.line-holder{
		display: none !important;
	}
	.flashcard{
		display: flex;
		flex-direction: column;
		justify-self: center;
		width: auto !important;
		height: 433px !important;
		border-radius: 8px;
		box-shadow: 0 4px 16px 0 rgba($primary-dark, 0.08);
		background-color: $primary-dark-background-color;
		font-size: 6vw !important;
		color: $primary-dark !important;
		margin: 0px -5px;
		border: none !important;
	}
	.flashcard-altered{
		display: flex;
		flex-direction: column;
		justify-self: center;
		width: auto !important;
		height: 433px !important;
		border-radius: 8px;
		box-shadow: 0 4px 16px 0 rgba($primary-dark, 0.08);
		background-color: $primary-dark-background-color;
		font-size: 6vw !important;
		color: $primary-dark !important;
		margin: 0px -5px;
		border: none !important;
	}


}

.letter-box {
	.lettercard {
	// font-size: 104px !important;
	// height: 400px !important;
	border-radius: 8px !important;
	box-shadow: 0 4px 16px 0 rgba($primary-dark, 0.08) !important;
	// padding: 110px 25px 0px 25px !important;
	}
		.back{
			position: relative;
			top: 42%;
		}
		.next{
			position: relative;
			top: 42%;
		}
}

.card{
	border-radius: 8px !important;
	box-shadow: 0 4px 16px 0 rgba($primary-dark, 0.08) !important;
	background-color: $primary-dark-background-color;
	font-size: 64px !important;
	padding: 38px 0px !important;

	span{
		color: $primary-dark;
	}
}

.mat-mdc-card-subtitle,
.mat-mdc-card-content {
	font-size: 14px;
}

.side_bar_display{
	width: 72px !important;
	// cursor: pointer;

	.side-top {
		padding: 32px 15px 0px !important;
	}
	.side-middle{
		overflow-y: auto!important;
		overflow: hidden !important;
		.ng-scroll-view {
			overflow-x: hidden;
		}
		.mat-expansion-panel-content{
			display: none;
		}
		.mat-tree {
			padding: 0px 0px 0px 0px !important;
			.nav-icons{
				left: 12px !important;
			}
			.mat-tree-node{
				padding-left: 5px !important;
			}
			.mat-tree-node[role="treeitem"] {
				display: none;
			}
		}
	}
	.side-bottom {
		position: relative;
		left: -17px;
		.user-icon {
			margin-right: 20px !important;
		}
	}
	.content_div{
		width: 95%;
	}
}

.side_navbar, .element-drag-disabled {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

// add save setting modalbox

.add-save-setting-modalbox {
	// height: 250px;
	width: 350px !important;

	.mat-mdc-dialog-container {
		padding: 0 !important;
		background-color: $whizzimo-modal-background;
		.mat-dialog-content {
			margin: 0;
			padding: 25px 24px;
			.mat-mdc-form-field {
				width: 100%;
			}
		}
		.mat-mdc-form-field-wrapper {
			padding: 0;
			width: 100%;
			.mat-mdc-form-field-infix{
				color: $primary-dark;
			}
		}
	}
	.add-save-setting {
		width: 350px;
		.popup-header {
			border-bottom: solid 1px rgba(72, 30, 159, 0.08);
			padding: 19px 24px;
			h1 {
				margin: 0;
				font-size: 20px;
				line-height: 26px;
				color: $primary-dark;
				font-weight: 600;
			}
		}
		.btn-container{
		    text-align: right;
    		justify-content: flex-end;
    		padding: 0 24px;
			.mat-button{
				@include modal-cancel-button;
			}
			.disable_save{
				opacity: 0.5;
			}
		}
		.mat-mdc-dialog-actions .mat-mdc-button-base {
			margin-left: 0;
		}
	}
}

// save setting modalbox

.save-setting-modalbox {
	// height: 350px;

	.mat-mdc-dialog-container {
		padding: 0 !important;
		background-color: $whizzimo-modal-background;
		.mat-dialog-content {
			margin: 0;
			padding: 25px 24px;
			.mat-mdc-form-field {
				width: 100%;
				.mat-mdc-form-field-wrapper {
					padding-bottom: 0;
				}
			}
			.mat-mdc-form-field-infix{
				color: rgba($whizzimo-black-white, 0.87);
			}

			.delete{
				margin: 18px 0px;
				.mat-button{
					padding: 0px 0px;
					background-color: transparent;
				}
				span{
					margin: 0px 18px;
					color: rgba($whizzimo-black-white, 0.87);
				}
			}
		}
		.mat-mdc-form-field-wrapper {
			padding: 0;
			width: 100%;
		}
	}
	.add-save-setting {
		width: 350px;
		.popup-header {
			border-bottom: solid 1px rgba($primary-dark, 0.08);
			padding: 19px 24px;
			h1 {
				margin: 0;
				font-size: 20px;
				line-height: 26px;
				color: $primary-dark;

				font-weight: 600;
			}
		}
		.btn-container{
      text-align: right;
      justify-content: flex-end;
      padding: 0 24px;
			.mat-button{
				@include modal-cancel-button;
			}
			.disable_save{
				opacity: 0.5;
			}
		}
		.mat-mdc-dialog-actions .mat-mdc-button-base {
			margin-left: 0;
		}
	}
}

.activity-modalbox{
	.mat-mdc-dialog-title{
		color: $primary-dark !important;
		font-weight: bold !important;
		letter-spacing: normal;
		margin-top: 24px;
	}
	.mat-mdc-dialog-content {
		max-height: 45vh;
		border-bottom: solid 1px rgba($primary-dark, 0.08);
		border-top: solid 1px rgba($primary-dark, 0.08);
		padding: 0 !important;

		.mat-mdc-list-item{
			border-bottom: solid 1px rgba($primary-dark, 0.08);
			margin-bottom: 0px !important;
			padding: 6px 24px 6px;
			margin-top: 0px;
			outline: none;
			height: auto !important;
			cursor: pointer;
			&.selected {
				background: #EEEEEE !important;
			}
		}
		.mdc-list-item__content{
			.activity_item{
				font-size: 18px !important;
				color: $primary-dark;
				line-height: 30px !important;
			}
			.activity_item_child{
				font-size: 14px !important;
				color: $whizzimo-activity-item-child;
			}
		}
	}
	.mat-mdc-button-base{
		float: right;
		position: relative;
    	top: 4px;
		@include modal-cancel-button;
		padding: 4px 24px;
		margin: 6px 0;
		.mdc-button__label {
			line-height: 40px !important;
			color: #866dff;
			font-size: 14px;
			font-family: "Roboto", sans-serif;
			text-align: center;
			font-weight: 500;
			letter-spacing: 0.4px;
			text-transform: uppercase;
		}
	}
}

/*====  Time reading modalbox   ====*/

.time_reading-modalbox{
	width: 350px;
	// height: 283px;
	.mat-mdc-dialog-container{
		background-color: $whizzimo-modal-background;
	}

	.mat-mdc-dialog-title {
		margin: 0 0 0px;
		color: $whizzimo-form-field-color;
		font-weight: bold !important;
		font-size: 20px;
		margin-bottom: 16px;
	}
	.mat-dialog-content{

		p{
			font-size: 16px;
			color: rgba($whizzimo-form-field-color, 0.56);
			line-height: 20px;
		}
	}
	.mat-mdc-dialog-actions{
		justify-content: flex-end;
		margin-right: 16px;
		margin-bottom: 8px;
		.mat-mdc-button-base{
			color: $interface-color-light-medium;
			font-size: 14px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			background: transparent;
			.mdc-button__label {
				font-weight: bolder !important;
				letter-spacing: 0.4px;
			}
		}
	}
}

/*====  Time reading modalbox   ====*/

/*====  score modalbox   ====*/

.score-modalbox{
	width: 350px;
	// height: 249px;

	.mat-mdc-dialog-title {
		margin: 0 0 0px;
		color: $whizzimo-modal-title-darkar;
		font-weight: bold;
		font-size: 20px;
		margin-top: 24px;
		margin-bottom: 8px;
	}
	.mat-dialog-content{

		p{
			font-size: 16px;
			color: rgba($whizzimo-modal-title-darkar, 0.56);
			line-height: 20px;
		}
	}
	.mat-mdc-dialog-actions{
		justify-content: flex-end;
		padding: 0px 0px !important;
		margin-right: 24px;
		margin-bottom: 8px;

		.mat-mdc-button-base{
			color: $interface-color-light-medium;
			font-size: 14px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			.mdc-button__label {
				font-weight: bolder !important;
				letter-spacing: 0.4px;
			}
		}
		button:first-child {
			margin-right: 8px;
		}
	}
}

/*====  score modalbox   ====*/

/*==== display score modalbox   ====*/

.display-score-modalbox{
	width: 400px;
	height: 350px;

	.mat-mdc-dialog-title {
		margin: 0 0 12px;
		color: $whizzimo-modal-title-darkar;
		font-weight: bold;
		font-size: 20px;
	}
	.mat-dialog-content{
		height: 70%;
		.mat-mdc-list-item{
			height: 30px;
			font-size: 16px;
			color: rgba($primary-dark, 0.56);
		}
	}
	.mat-mdc-dialog-actions{
		justify-content: flex-end;
		padding: 0px 0px !important;

		.mat-mdc-button-base{
			color: $interface-color-light-medium;
			font-size: 14px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: 0.4px;
			text-transform: uppercase;
		}
	}
}

/*==== display score modalbox   ====*/

/*====  activity-common-modalbox  ====*/

.activity-common-modalbox{
	height: 364px !important;
	.mat-mdc-dialog-container {
		padding: 0px 0px;
		background-color: $whizzimo-modal-background;
	}
}

/*====  activity-common-modalbox  ====*/

/*==== course list modalbox   ====*/

.course-list-modalbox{
	width: 350px !important;
	height: auto !important;

	.mat-mdc-dialog-container{
		overflow: hidden;
		background-color: $whizzimo-modal-background;
	}
	.mat-mdc-dialog-title,
	.mdc-dialog__title {
		color: $primary-dark !important;
		font-weight: bold !important;
		margin-top: 24px;
		font-size: 18px !important;
	}
	.mat-mdc-dialog-content {
		max-height: 45vh;
		border-bottom: solid 1px rgba($interface-color-darkar-medium, 0.08);
		border-top: solid 1px rgba($interface-color-darkar-medium, 0.08);
		padding: 0px 0px !important;

		.mat-mdc-list-item{
			border-bottom: solid 1px rgba($interface-color-darkar-medium, 0.08);
			margin-bottom: 0px !important;
			padding: 6px 0px 6px;
			margin-top: 0px;
			outline: none;
			height: auto !important;
			cursor: pointer;
		}
		.mdc-list-item__content{
			padding: 0px 24px !important;

			.activity_item{
				font-size: 16px !important;
				color: $primary-dark;
				line-height: 30px !important;
			}
			.activity_item_child{
				font-size: 14px !important;
			}
		}

	}
	.mat-mdc-button-base{
		color: $interface-color-light-medium !important;
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: 0.4px;
		text-transform: uppercase;
		float: right;
		margin: 10px;
		position: relative;
		background: transparent;
	}
}

/*==== course list modalbox   ====*/

/*==== missing tiles modalbox   ====*/

.missing-tiles-modalbox{
	.mat-mdc-dialog-title, .description {
		.dark-theme & {
			color: white !important;
		}
	}
	.mat-mdc-dialog-container{
		background-color: $whizzimo-modal-background;
	}
	.mat-dialog-content{
		// max-height: 500px !important;
		margin: 0;
		padding: 0;
		.mat-mdc-dialog-title{
			color: $primary-dark;
			font-size: 24px;
		}
		.mat-typography{
			.mat-typography-p{
				color: rgba($primary-dark, 0.56);
			}
		}
	}

	.mat-mdc-dialog-actions {
		.add_tile_missing{
			background-color: #ffb5a0;
			color: $primary-color;
			box-shadow: none;
			font-size: 14px;
			display: flex;
			flex-direction: row;
			.mdc-button__label {
				width: 100%;
				color: $primary-color;
			}
		}
		.skip_tile_missing{
			color: $primary-color;
			font-size: 14px;
			align-self: center;
			display: flex;
			flex-direction: row;
			background-color: $whizzimo-grey-white;
			.mdc-button__label {
				width: 100%;
				color: $primary-color;
			}
		}
	}
}

/*==== missing tiles modalbox   ====*/

/*==== small modalbox   ====*/
.small-dialog {
	max-width: 20vw !important;
	.mat-mdc-dialog-title{
		color: $primary-dark;
	}
	.mat-dialog-content{
		color: $whizzimo-font-light;
	}
}
/*==== small modalbox   ====*/


/*==== scrollbar   ====*/

.ng-scrollbar{
	--scrollbar-size: 8px !important;
	--scrollbar-thumb-color: rgba(94, 175, 255, 0.5)!important;
	--scrollbar-thumb-hover-color: dodgerblue;
	--scrollbar-border-radius: 4px;
}

.scrollbar {
    overflow-y: auto;
  }
  .scrollbar::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  .scrollbar::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  .scrollbar::-webkit-scrollbar-thumb {
    background: rgba(94, 175, 255, 0.3);
    border-radius: 5px;
  }

  /* Handle on hover */
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background:rgba(94, 175, 255, 0.5);
  }


  .scrollbar-grey {
    overflow-y: auto;
  }
  .scrollbar-grey::-webkit-scrollbar {
    width: 15px;
  }

  /* Track */
  .scrollbar-grey::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    // border-radius: 5px;
	background: white;
  }

  /* Handle */
  .scrollbar-grey::-webkit-scrollbar-thumb {
    background:rgba(179, 179, 179, 0.5);
    border-radius: 5px;
  }

  /* Handle on hover */
  .scrollbar-grey::-webkit-scrollbar-thumb:hover {
    background:rgba(179, 179, 179, 0.5);
  }


/*==== scrollbar   ====*/

/*==== curriculum   ====*/

.course_content_custom{
	height: 154px !important;
  min-height: 154px !important;
	padding: 32px 24px !important;
	background-color: $primary-dark-background-color;
	.header{
		position: relative;
		// height: 31px;
		display: flex;
		align-items: center;
		h2{
			@include h1-header;
			margin: 0;
		}
		.help_icon{
			position: relative;
			left: 10px;
			top: 0px;
		}

	}
	.course-button{
		@include manage-course-button;
		height: 40px !important;
		width: auto !important;
		box-shadow: none !important;
		top: 50px;
		right: 15px;
		min-width: auto;
		.mdc-button__label{
			line-height:40px !important;
		}
	}
}
.dashboard_content_toggle{
	background-color: $primary-dark-background !important;
	.mat-mdc-tab-body-wrapper{
		padding: 0px 24px;
		overflow: initial;
		margin-bottom: 50px !important;
		.reoder{
			@include fill-button;
			margin: 32px 10px 0px;
			box-shadow: none !important;
			line-height: 1.2;
			height: 40px;
			.mdc-button__label{
				color: $secondary-color;
			}
		}
		.done{
			@include fill-button;
			margin: 32px 10px 0px;
			box-shadow: none !important;
			line-height: 1.2;
			height: 40px;
			.mdc-button__label{
				color: $secondary-color;
			}
		}
		.search-and-actions {
			background-color: $primary-dark-background !important;
		}
    .quick-start{
      @include fill-button;
      margin: 32px 10px 0px;
      box-shadow: none !important;
      line-height: 1.2;
	  height: 40px;
      .mat-button-wrapper{
        color: $secondary-color;
      }
    }
	}
	.mat-mdc-tab-header{
		border: none;
		.mat-mdc-tab-label-container{
			background-color: $primary-dark-background-color;
			padding: 0px 24px;
			.mat-mdc-tab{
				opacity: 1;
				font-family: Roboto, "Helvetica Neue", sans-serif;
				font-size: 14px !important;
				font-weight: 500 !important;
				flex-grow: 0 !important;
				min-width: 160px;

				// NOTE: 
				&[aria-disabled='true'] {
					opacity: 0.32;
				}

				.mdc-tab__text-label {
					color: $whizzimo-mat-tab-label !important;
				}
			}
			.mat-ink-bar{
				height: 4px;
				background-color: $whizzimo-mat-tab-label;
			}
			.mdc-tab--active{
				opacity: 1 !important;
			}
			.mat-mdc-tab .mdc-tab-indicator__content--underline {
				border-top-width: 4px !important;
				border-color: $interface-color-darkar-medium !important;
			}
		}
	}
}
.dashboard_custom_card{
	padding: 24px 0px;
	.blank_div_container{
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		margin: 32px 0px;
		.add_workbook_blank{
			background-color: $interface-color-light-medium !important;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			margin: 9px 5px 0px !important;
			background-image: none;
			span{
				color: #fff !important;
				margin-bottom: 10px;
			}
		}
		.blank_div{
			background-color: $whizzimo-blnak-new-course !important;
			height: $card-height !important;
			border-radius: 8px;
			box-shadow: none !important;
			margin: 9px 5px 0px !important;
		}
	}

	.new-workbook{
		height: $card-height !important;
		border: none !important;
		margin-right: 5px !important;
		border-radius: 8px !important;
		background: $newworkbook-bg;
		background-image: $border-dash-image;
		display: flex;
    	flex-direction: column;
		justify-content: space-between;

		@media only screen and (max-width: $largedesktopview){
			width: $card-width-1920 !important;
		}
		@media only screen and (max-width: $mediamdesktopview){
			width: $card-width-1680 !important;
		}
		@media only screen and (max-width: $smalldesktopview){
			width: $card-width-1366 !important;
		}
		@media only screen and (max-width: $extrasmalldesktopview){
			width: $card-width-1280 !important;
		}
		@media only screen and (max-width: $tabView){
			width: $card-width-1024 !important;
		}

		// div{
		// 	margin-top: 10px;
		// }
		span{
			color: $interface-color-light-medium;
			font-size: 14px;
			text-transform: uppercase;
			// margin-bottom: 10px;
		}
	}
	.workbook{
		height: $card-height !important;
		border-radius: 8px;
  		box-shadow: $box-defult-shadow !important;
		background-color: $primary-dark-background-color !important;
		margin: 9px 5px 0px !important;

		.mat-mdc-card-header {
			color:  $primary-dark;
			text-align: left;
			font-weight: 600;
			.mat-mdc-card-header-text {
				margin: 0 0px !important;
			}
			.red-circle{
				width: 8px;
				height: 8px;
				background: #ff5a5a;
				border-radius: 50px;
				position: absolute;
				right: 4px;
				top:4px;
			}
		}
		.actions{
			position: relative;
			// height: 45px;
			// height: 15px;
			.begin-button{
				position: absolute;
				left: -6px;
				top: 10px;
				@include transparent-button;
			}
			.ellipsis{
				position: absolute;
				right: 0;
				background:none;
				box-shadow: none;
				.mdc-button__label{
					color: rgba($whizzimo-black-white,0.3) !important;
				}

			}
		}

	}
	.content{
		@media only screen and (max-width: $largedesktopview){
			width: $card-width-1920 !important;
		}
		@media only screen and (max-width: $mediamdesktopview){
			width: $card-width-1680 !important;
		}
		@media only screen and (max-width: $smalldesktopview){
			width: $card-width-1366 !important;
		}
		@media only screen and (max-width: $extrasmalldesktopview){
			width: $card-width-1280 !important;
		}
		@media only screen and (max-width: $tabView){
			width: $card-width-1024 !important;
		}
	}

}

.dashboard_custom_card_reoder{
	padding: 24px 0px;
	.new-workbook{
		width: $card-width-1366 !important;
		height: 118px !important;
		border: none !important;
		border-radius: 8px !important;
		margin-right: 5px !important;
		//padding: 24px 16px;
		background: $newworkbook-bg;
		background-image: $border-dash-image;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		div{
			margin-top: 10px;
		}
		span{
			color: $interface-color-light-medium;
			font-size: 13px;
			text-transform: uppercase;
			margin-bottom: 10px;
		}
	}
	.cdk-drop-list{
		width: $card-width-1366 !important;
		.workbook{
			background-color: $primary-dark-background-color !important;
			  .example-handle{
				.mat-mdc-card-header {
				  color: $primary-dark;
				}
			  }
		  }
	}
	.content{
		padding: 7px 3px !important;
	}
	.workbook{
		height: 118px;
		border-radius: 8px;
		box-shadow: $box-defult-shadow !important;
		background-color: $primary-dark-background-color !important;
		margin: 9px 5px 0px !important;

		.mat-mdc-card-header {
			color: $primary-dark;
			text-align: left;
			.mat-mdc-card-header-text {
				margin: 0 0px !important;
			}
		}
		.actions{
			color: $primary-dark;
			position: relative;
			height: auto;
			padding: 0px 24px !important;
			justify-content: start !important;
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 0px !important;
		}
		.ellipsis{
			background:none;
			box-shadow: none;
			.mdc-button__label{
				color: $ellipsis-icon-color !important;
			}

		}
		.action-div {
			display: flex;
			justify-content: space-between;
		}
		.remove-btn {
			font-weight: bold;
			background-color: transparent;
			border: none;
			margin-top: -16px;
			text-transform: uppercase;
			cursor: pointer;
		}

	}

}

.search-field {
    // min-width: 45%;
    // max-width: 45%;
    position: relative;
	.search-field_input {
		width: 100%;
		border: 1px solid rgba($primary-dark, 0.08);
		padding: 10px 0px 10px 40px;
		border-radius: 4px;
		outline: none;
		background-color: $whizzimo-input-background-transparent;
		color: $whizzimo-black-white;
		font: inherit;

		&::placeholder{
			color: rgba($whizzimo-black-white, 0.32);
		}
		&::-moz-placeholder{
			color: rgba($whizzimo-black-white, 0.32);
		}
		&::-webkit-input-placeholder{
			color: rgba($whizzimo-black-white, 0.32);
		}
	}

	.clear-button {
		position: absolute;
		right: 0rem;
		top: 50%;
		transform: translateY(-50%);
		background: transparent;
		color: #999;
	
		&:hover {
		  color: #333;
		}
	  }

    &::before{
        position: absolute;
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background-image: url($search-icon);
        top: 7px;
        left: 10px;
    }
}


/*==== curriculum   ====*/

/*====  work book menu modalbox  ====*/

.work-book-menu{
	.mat-mdc-menu-content{
		.mat-mdc-menu-item{
			color: $primary-dark;
			background-color: transparent;
			&:last-child{
				color: $interface-color-soft-darkar;
			}
			&:hover:not([disabled]) {
				background: rgba($primary-dark, 0.04);;
			}
			.mdc-list-item__primary-text {
				font-family: Roboto, "Helvetica Neue", sans-serif !important;
				font-size: 14px !important;
				font-weight: 400 !important;
			}
		}
	}
}

/*====  work book menu modalbox  ====*/

/*====  manage course modalbox  ====*/

.manage-course-modalbox{
	width: 350px !important;
	.mat-mdc-dialog-container{
		padding: 0px 0px;
		box-shadow: $modal-shadow !important;
		background-color: $whizzimo-modal-background;
		.mat-mdc-dialog-title {
			margin: 0 0 20px;
			display: block;
			padding: 19px 24px;
			border-bottom: 1px solid rgba($primary-dark, 0.08);
			color: $primary-dark;
			font-size: 20px;
			font-weight: bold;
		}
		.mat-mdc-dialog-content {
			padding: 0 24px;
			max-height: 65vh;
			.mat-mdc-form-field{
				width: 100%;
				.mat-mdc-form-field-wrapper {
					padding-bottom: 0 !important;

				}
				.mat-mdc-form-field-infix {
					color: $whizzimo-form-field-color;
					font-size: 16px;
					.mat-mdc-form-field-label{
						color: $whizzimo-form-field-color;
						opacity: 0.56;
					}
					.mdc-form-field-outline-thick {
						color: rgba($primary-dark, 0.08);
					}
				}
			}

			.manage_modal_p{
				font-size: 16px;
				color: rgba($primary-dark, 0.56);
				margin: 0 0 24px;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				span{
					margin-right: 24px;
					height: 23px;
				}
			}
		}
		.mat-mdc-dialog-actions {
			padding: 8px 0;
			display: flex;
			flex-wrap: wrap;
			min-height: 52px;
			align-items: center;
			margin: 6px 10px;
			justify-content: flex-end;
			.cancel{
				@include modal-cancel-button;
			}
			.save{
				@include modal-save-button;
			}
			.share_copy_btn{
				color: $interface-color-light-medium;
				margin-left: 5px;
				font-size: smaller;
			}
		}

	}

}

/*====  manage course modalbox  ====*/

/*====  wordlist modalbox  ====*/

.wordlist_modal{
	width: 350px !important;
	.mat-mdc-dialog-container{
		padding: 16px 24px;
		border-radius: 8px;
		box-shadow: $modal-shadow !important;
		background-color: $whizzimo-modal-background;
		.mat-mdc-dialog-title {
			color: $primary-dark;
			font-size: 20px;
			font-weight: bold;
			margin: 0 0 5px;
			padding: 0;
		}
		.scrollable{
			overflow: hidden !important;
			p{
				color: rgba($primary-dark, 0.56);
    			font-size: 16px;
			}
		}
		.mat-mdc-dialog-actions{
				padding: 0px 0;
				min-height: 32px;

			.mat-button{
				position: relative;
				top: 20px;
				left: 15px;
				.mdc-button__label{
					font-size: 14px;
					color: $interface-color-light-medium;
					font-weight: 600;
					text-transform: uppercase;
				}
			}


		}
	}
}

/*====  wordlist modalbox  ====*/

/*==== tutorial dialog modalbox  ====*/

.tutorial_dialog_modal{
	.mat-mdc-dialog-container{
		padding: 1rem 0.5rem;
		border-radius: 8px;
		box-shadow: $modal-shadow !important;
		background-color: $whizzimo-modal-background;
	}
}

/*====  tutorial dialog modalbox  ====*/

//---tutorials---//


.setting_header_div_tutorials{
	background:$primary-dark-background-color;
	padding: 6px 24px;
	display: flex;
	position: sticky;
	top: 0;
	z-index: 10;
	width: 100%;
	height: 96px;
	display: flex;
	align-items: center;

	h2{
		@include  h1-header;
		margin: 0 !important;
	}
}

.page_tutorials{
	p{
		color: $whizzimo-font-light;
		font-size: 16px !important;
		// width: 62%;
    	line-height: normal !important;
    	margin: 15px 0 24px;
	}
}

//---tutorials---//

//---sharing----//

.sharing_header_div{
	background: $primary-dark-background-color;
    padding: 0px 24px;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 96px;
	align-items: center;
	justify-content: space-between;
	.help_icon {
		position: relative;
		top: 7px;
		left: 10px;
	}
	.sharing_header_div_left{
		display: flex;
		.settings-header{
			@include h1-header;
			margin: 0;
		}
	}
	.mat-mdc-button-base{
		@include fill-button;
		line-height: 1.2;
		box-shadow: none;
		height: auto;
		padding: 0 16px;
		.mdc-button__label{
			font-weight: bold;
			letter-spacing: 0.4px !important;
		}
	}
	.import-btn {
		background: $interface-color-soft-medium;
		border-color: $interface-color-soft-medium;
		.mdc-button__label {
			color: $interface-color-darkar;
		}
	}
}

//---sharing----//


//*=== sharing page  ====*//

.page_sharing{
	padding: 34px 24px;
	background: $primary-dark-background;
	.mat-mdc-form-field {
		.mat-mdc-form-field-wrapper {
			padding-bottom: 0 !important;
		}
	}
	.page_sharing_heading{
		color: $primary-dark;
		font-size: 20px;
		font-weight: bold;
	}
	.page_sharing_p{
		font-size: 16px;
		color: $paragraph;
		width: 38vw;
		line-height: normal;

	}
	.sharing_from{
		margin: 24px 0px 0px;
		width: 209px;
		position: relative;
		.sharing_from_input{
			border: 1px solid rgba($primary-dark, 0.08);
			padding: 10px 0px 10px 40px;
			border-radius: 50px;
			outline: none;
			background-color: $whizzimo-input-background-transparent;
			color: $whizzimo-black-white;
			font: inherit;

			&::placeholder{
				color: rgba($whizzimo-black-white, 0.32);
			}
			&::-moz-placeholder{
				color: rgba($whizzimo-black-white, 0.32);
			}
			&::-webkit-input-placeholder{
				color: rgba($whizzimo-black-white, 0.32);
			}
		}
		&::before{
			position: absolute;
			content: "";
			display: block;
			width: 24px;
			height: 24px;
			background-image: url($search-icon);
			top: 7px;
			left: 10px;
		}

	}
}

.sharing_custom_card{
	display: flex;
	flex-wrap: wrap;
	padding: 0px 24px;
	.sharing_custom_card{
		font-size: 19px;
		color: $primary-dark;
		font-weight: bold;
		margin-bottom: 20px;
		text-align: center;
		margin-top: 30px;
	}
	.new-workbook {
		width: $workbook-width-content !important;
		height: $workbook-height-content;
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		align-items: center;
		border: none;
		border-radius: 8px;
		margin-right: 5px !important;
		padding: 24px 0px;
		background: $newworkbook-bg;
		background-image: $border-dash-image;
		@media only screen and (max-width: $tabView){
			width: 30% !important;
		}
		.icon {
			width: 48px;
			height: 48px;
			background: $interface-color-light-medium;
			border-radius: 5px;
			margin: 10px 0px 10px 0px;
			position: relative;
			z-index: 2;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 32px;
		}
		.new_workbook_heading{
			font-size: 20px;
			color: $primary-dark;
			font-weight: bold;
			margin-bottom: 18px;
		}
		.mdc-button__label{
			color: $interface-color-light-medium;
			font-size: 14px;
		}

	}
	.content {
		width: $workbook-width-content !important;
		padding: 17px 0px;
		border-radius: 8px;
		box-shadow: 0 4px 8px 0 rgba($primary-dark, 0.04);
		background-color: $primary-dark-background-color;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: center;
		padding-left: 15px;
		padding-right: 15px;
		margin: 9px 5px 0px !important;
		@media only screen and (max-width: $tabView){
			width: 30% !important;
		}

		&::before{
			position: absolute;
			width: 100%;
			content: "";
			height: 1px;
			display: block;
			left: 0;
			// background: $whizzimo-border-transparent;
			top: 153px;
			z-index: 1;
			border-top: 1px solid #f1edf8;
			.dark-theme & {
				border-top: 1px solid rgba(255, 255, 255, 0.08) !important;
			}
		}

		.mat-mdc-card-header {
			display: block;
			flex-direction: row;
			font-size: 20px;
			font-weight: bold;
			color: $primary-dark;
			text-align: center;
			min-height: 48px;
			padding: 0;
		}
		.mat-mdc-card-actions {
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: transparent;
			.mdc-button__label{
				color: $interface-color-light-medium;
				font-size: 14px;
			}
			.icon {
				width: 48px;
				height: 48px;
				background: $interface-color-light-medium;
				border-radius: 5px;
				margin: 20px 0px 10px 0px;
				position: relative;
				z-index: 2;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.share_code{
				margin-bottom: 15px;
				p{
					margin: 15px 0 10px;
					font-size: 16px;
					color: $primary-dark;
					font-weight: 600;
				}
				span{
					color: rgba($primary-dark, 0.56);
					font-size: 16px;
				}
				.sharing_copy{
					color: rgba($whizzimo-black-white, 0.87);
				}
			}
			.setting_include{
				display: flex;
				flex-direction: column;
				align-items: center;
        		text-align: center;
				width: 163px;
				p{
					margin: 15px 0 10px;
					font-size: 16px;
					color: $primary-dark;
					font-weight: 600;
				}
				span{
					color: rgba($primary-dark, 0.56);
					font-size: 16px;
				}
				.mdc-button__label{
					color: $interface-color-light-medium;
				}
			}
			.delete-button{
				margin: 5px 0px 0px;
				.mdc-button__label{
					color: #ff7463;
					font-size: 14px;
				}
			}
		}
	}
}

/*=== sharing page  ====*/

// add-collection-modalbox

.add-collection-modalbox{
	width: 350px !important;
	.mat-mdc-dialog-container{
		padding: 0px;
		overflow: hidden;
		background-color: $whizzimo-modal-background;
		.mat-mdc-dialog-title {
			padding: 19px 24px;
			display: block;
			color: $whizzimo-form-field-color;
			font-size: 20px;
			font-weight: bold;
		}
		.mat-mdc-dialog-content {
			padding: 0;
		}
		.mdc-list{
			padding: 0px 0px;
			.mat-mdc-list-item{
				height: auto;
				&:not(:last-child) {
					border-bottom: 1px solid $border-pale-grey;
					.dark-theme & {
						border-bottom: 1px solid rgba(241, 237, 248, 0.12) !important;
					}
				}
				.mdc-list-item__content{
					padding: 0px 24px 16px;
					cursor: pointer;
					.activity_item{
						font-size: 16px !important;
						color: $primary-dark;
					}
					.activity_item_child{
						color: rgba($primary-dark, 0.56);
						font-size: 14px !important;
					}
				}

			}

		}

	}
	.mat-mdc-button{
		float: right;
		margin-right: 10px;
		margin-bottom: 10px;
		.mdc-button__label{
			text-transform: uppercase;
			color: $interface-color-light-medium;
			font-size: 14px;
		}
	}
}

//rename-modalbox

.rename-modalbox{
	width: 350px !important;
	.mat-mdc-dialog-container{
		background-color: $whizzimo-modal-background;
	}
	.mat-mdc-dialog-title {
		color: $whizzimo-form-field-color;
		padding: 19px 24px 0;
		display: block;
		font-size: 20px;
		font-weight: bold;
	}
	.mat-mdc-form-field{
		width: 100%;
		.mat-mdc-form-field-infix {
			padding: 1em 0 1em 0;
			color: $primary-dark;
		}
		.mat-mdc-form-field-label {
			color: rgba($whizzimo-black-white, 0.6);
		}
	}
	.mat-mdc-dialog-actions {
		justify-content: flex-end;
		padding: 0 24px 10px;
		.mat-mdc-button-base{
			@include modal-cancel-button;
		}

	}
}

//import-collection-modalbox

.import-collection-modalbox{
	width: 350px !important;
	.mat-mdc-dialog-container{
		background-color: $whizzimo-modal-background;
	}
	.mat-mdc-dialog-title {
		color: $whizzimo-form-field-color !important;
		padding: 19px 24px 0;
		display: block;
		font-size: 20px !important;
		font-weight: bold !important;
	}
	.mat-mdc-form-field{
		width: 100%;
		.mat-mdc-form-field-infix {
			padding: 0.8em 0 1em 0;
			color: $primary-dark;
		}
		.mat-mdc-form-field-label {
			color: rgba($whizzimo-black-white, 0.6);
		}
		.mat-mdc-form-field-subscript-wrapper {
			display: none;
		}
	}

	.mat-mdc-dialog-actions {
		justify-content: flex-end;
		padding: 0 24px 10px;
		.mdc-button__label{
			color: $interface-color-light-medium;
			font-size: 14px;
			text-transform: uppercase;
		}
	}
}

//delete-course-modalbox

.mat-mdc-dialog-container{
	.mat-mdc-dialog-actions {
		.mdc-button__label{
			color: $interface-color-light-medium;
		}
	}
}

//delete lesson plan step
.delete-lesson-plan-step-actions{
	.mat-flat-button {
		color: $interface-color-light-medium !important;
	}
}

//type edit modalbox
.type-edit-modalbox{
	overflow: hidden;
	width: 70vw !important;
	.mat-mdc-dialog-title {
		padding: 19px 24px 0;
		display: block;
		font-size: 20px !important;
		font-weight: bold !important;
		letter-spacing: normal;
		body:not(.dark-theme) & {
			color: $primary-color !important;
		}
		.dark-theme & {
			color: white !important;
		}
	}
	.mat-mdc-form-field{
		width: 100%;
		.mat-mdc-form-field-infix {
			padding: 1em 0 1em 0;
			color: $primary-dark;
		}
		.mat-mdc-form-field-subscript-wrapper {
			display: none;
		}
	}

	.mat-mdc-dialog-actions {
		justify-content: flex-end;
		padding: 8px 24px;
		.mdc-button__label{
			color: $interface-color-light-medium;
			font-size: 14px;
			text-transform: uppercase;
		}
	}
	.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
		height: 0px !important;
	}
	.mat-mdc-focused {
		background-color: none;
	}
	#no-padding .mat-mdc-form-field-infix {
		padding: 0px !important;
		border-top: 0px !important;
		textarea{
			background-color: $whizzimo-modal-background;
			border: 2px solid $whizzimo-border-transparent;
			color: $whizzimo-black-white;
		}
	}
}


//collection-added-modalbox

.collection-added-modalbox{
	width: 350px !important;
	.mat-mdc-dialog-title {
		color: $whizzimo-modal-title-darkar;
		padding: 19px 24px 0;
		display: block;
		font-size: 20px;
		font-weight: bold;
	}
	.mat-dialog-content{
		font-size: 16px;
		color: rgba($primary-dark, 0.56);
		span{
			color: $primary-dark;
		}
	}
	.mat-mdc-dialog-actions {
		justify-content: flex-end;
		.mdc-button__label{
			color: $interface-color-light-medium;
			font-size: 14px;
			text-transform: uppercase;
		}
	}
}


/*===  sharing page  ====*/

.sharing_header_div_collection{
	background: $primary-dark-background-color;
    padding: 0px 24px;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 120px;
    align-items: center;
	justify-content: space-between;

	.sharing_header_left_collection{
		display: flex;
		flex-direction: column;
		span{
			color: $interface-color-light-medium;
			font-size: 14px;
			margin-bottom: 8px;
		}
		.settings-header {
			color: $primary-dark;
			margin: 0 0 0;
		}
	}
	button {
		height: 40px !important;
		width: auto !important;
		right: 15px;
		min-width: auto;
		box-shadow: none !important;
		@include sharing-done-button;
	}
}
.collection_field {
    margin: 24px 24px !important;
	padding: 0 !important;
	.mat-mdc-form-field-infix {
		width: 302px;
		color: $primary-dark;
		font-size: 16px;
		.mat-mdc-form-field-label{
			color: $primary-dark;
			opacity: 0.64;
		}
	}
	.mdc-form-field-outline {
		color: rgba($primary-dark, 0.64);
	}
}
.sharing_custom_card_collection{
	display: flex;
    flex-wrap: wrap;
	padding: 0px 24px;
	.content {
		width: 23.6% !important;
		padding: 17px 17px;
		border-radius: 8px;
		box-shadow: 0 4px 8px 0 rgba($primary-dark, 0.04);
		background-color: $primary-dark-background-color;
		display: flex;
		justify-content: space-between;
    	flex-direction: column;
    	align-items: flex-start;
		margin: 9px 5px 0px !important;
		.mat-mdc-card-header {
			display: block;
			font-size: 16px;
			font-weight: bold;
			color: $primary-dark;
			padding: 0 !important;
		}
		.mat-mdc-card-actions {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			position: relative;
			padding: 10px 0 0;
			margin-bottom: -10px;
			min-height: auto;
			.add-button{
				.mdc-button__label{
					color: $interface-color-light-medium;
					font-size: 14px;
					font-weight: bold;
				}
			}
			.delete-button{
				.mdc-button__label{
					color: #ff7463;
					font-size: 14px;
					font-weight: bold;
				}
			}
			.ellipsis {
				position: absolute;
				right: -20px;
				.mdc-button__label {
					color: #cac4d7 !important;
				}
			}
		}
	}

}

/*===  sharing page  ====*/

/*===  Account  ====*/

.account_content_div{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
    background: #FFF;
    margin: 24px;
	border-radius: 8px;
	.each_row{
		display: flex;
		border-bottom: 1px solid $whizzimo-border-transparent;
		.description-section {
			padding: 24px 24px;
			@media only screen and (max-width: $tabView){
				width: 30% !important;
			}
			h3{
				font-size: 20px;
				font-weight: bold;
				color: $primary-dark;
			}
		}
		.description_option {
			padding: 24px 24px;
			width: 50%;
			@media only screen and (max-width: $tabView){
				width: 70% !important;
			}
			h4{
				color: $primary-dark;
				font-size: 16px;
			}
			span{
				color: $paragraph;
				font-size: 16px;
				margin-left: 12px;
			}
			.add_card{
				font-size: 14px;
				color: $interface-color-light-medium;
				text-transform: uppercase;
				cursor: pointer;
			}
		}
	}
}
.plan_description_div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	background-color: transparent;
	padding: 24px 24px;
	flex-wrap: wrap;
	.mat-mdc-button-base{
		@include fill-button;
		.mdc-button__label{
			font-style: italic;
			letter-spacing: 0.4px;
			text-transform: capitalize !important;
			font-size: 18px;

		}
	}

}

/*===  Account  ====*/

/*===  add credit card modalbox  ====*/

.add-credit-card-modalbox{
	width: 350px;
	border-radius: 8px !important;
	.mat-mdc-dialog-title{
		font-size: 20px;
		color: $whizzimo-darkblue-white;
		font-weight: bold;
		margin: 0 0 0;
	}
	.mat-mdc-form-field{
		.mat-mdc-form-field-wrapper{
			padding-bottom: 0px !important;
		}

		.mat-mdc-form-field-infix{
			padding: 0 0;
			input[type="text"]{
				border-radius: 4px;
				width: 100% !important;
				height: 56px !important;
				font-size: 16px !important;
				color: $primary-dark !important;
				border: 1px solid rgba($primary-dark, 0.64);;
				padding-left: 10px;
			}
			.mat-mdc-form-field-label {
				top: 37px;
				left: 16px;
				color: $primary-dark;
				font-size: 16px;
				opacity: 0.64;
			}

		}
		.mat-mdc-form-field-underline {
			background-color: transparent !important;
		}
		.mat-mdc-form-field-ripple{
			display: none;
		}

	}
	.mat-stroked-button{
		border-color: rgba($whizzimo-black-white, 0.12);
	}
	.mat-mdc-checkbox-frame {
		border-color: rgba($whizzimo-black-white, 0.12);
	}
			.mat-mdc-form-field-should-float {
				.mat-mdc-form-field-label {
					background-color: $whizzimo-modal-background;
					width: auto !important;
					top: 32px !important;
					padding: 0px 7px;
					opacity: 1 !important;
				}
			}

	.card_date_details{
		display: flex;
		justify-content:space-between;
	}
	.btn-default {
		background-color: transparent !important;
		font-weight: bold;
		color: $interface-color-light-medium;
		text-transform: uppercase;
		font-size: 14px !important;
	}
	.agree_span{
		color: $primary-dark;
		opacity: 56%;
	}
	.mat-mdc-checkbox-checked{
		.mat-mdc-checkbox-background {
			background-color: $interface-color-light-medium !important;
		}
	}
}


/*===  add credit card modalbox  ====*/

/*===  successful-update-modalbox  ====*/

.successful-update-modalbox{
	width: 350px !important;
	.mat-mdc-dialog-container{
		padding: 19px 24px 5px;
		background-color: $whizzimo-modal-background;
		.mat-mdc-dialog-title{
			color: $whizzimo-form-field-color;
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 20px;
		}
		.msg_success{
			font-size: 16px;
			color: rgba($primary-dark, 0.56);
		}
		.ok_success_btn{
			width: auto !important;
			margin-right: 0px;
			float: right;
			margin-top: 20px;
			@include transparent-button;

		}
		.cdk-focused {
			display: none;
		}
	}
}

/*===  successful-update-modalbox  ====*/

/*===  selected-plan-modalbox  ====*/

.selected-plan-modalbox{
	width: 350px !important;
	.mat-mdc-dialog-container{
		padding: 17px 24px 5px;
		background-color: $whizzimo-modal-background;
		.titel{
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 20px;
			color: $whizzimo-form-field-color;
			display: block;
		}
		.apply_promocode_form{
			.mat-mdc-form-field{
				width: 100%;
				.mat-mdc-form-field-wrapper{
					padding-bottom: 0px !important;
				}

				.mat-mdc-form-field-infix{
					padding: 0 0;
					input[type="text"]{
						border-radius: 4px;
						width: 100% !important;
						height: 56px !important;
						font-size: 16px !important;
						color: $primary-dark !important;
						border: 1px solid rgba($primary-dark, 0.64);;
					}
					.mat-mdc-form-field-label {
						top: 37px;
						left: 16px;
						color: $primary-dark;
						font-size: 16px;
						opacity: 0.64;
					}

				}
				.mat-mdc-form-field-underline {
					background-color: transparent !important;
				}
				.mat-mdc-form-field-ripple{
					display: none;
				}

			}

			.mat-mdc-form-field-should-float {
				.mat-mdc-form-field-label {
					background-color: #fff;
					width: auto !important;
					background: #fff;
					top: 32px !important;
					padding: 0px 7px;
					opacity: 1 !important;
				}
			}
		}

		.comfrm_new_plan_btn{
			width: auto !important;
			margin-right: 0px;
			float: right;
			margin-top: 20px;
				.mdc-button__label{
					color: $interface-color-light-medium;
				}
		}
		.cdk-focused {
			display: none;
		}
	}

}

/*===  selected-plan-modalbox  ====*/

/*====  cancel-subscriptiton-modalbox  ====*/

.cancel-subscriptiton-modalbox{
	// width: 30rem !important;
	// height: 80vh !important;
	transform: scale(0.90) !important;

	app-cancel-subscription {
		display: block;
		height: 100%;
	}

	.mat-mdc-dialog-container{
		padding: 17px 24px 10px;
		background-color: $whizzimo-modal-background;
		.mat-mdc-dialog-title{
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 20px;
			color: $whizzimo-form-field-color;
			display: block;
		}
		.mat-typography {
			padding: 0px;
			// padding-top: 20px;
			// padding-bottom: 20px;
		}
		.btn-default{
			width: auto !important;
			margin-right: 0px;
			margin-bottom: 10px;
			background-color: transparent;
				.mdc-button__label{
					color: $interface-color-light-medium;
				}
		}
		.cdk-focused {
			display: none;
		}
		.are-you-sure-span{
			color: $whizzimo-font-light;
			font-size: 16px;
		}
	}
}

/*====  cancel-subscriptiton-modalbox  ====*/


.whizzimolibraries_container{
	.drag-drop-box{
		.new-workbook{
			@media only screen and (max-width: $extrasmalldesktopview){
				width: $card-width-1280 !important;
			}
			@media only screen and (max-width: $tabView){
				width: $card-width-1024 !important;
			}
		}
		.content{
			margin: 0px 9px 9px;
			background-color: $primary-dark-background-color ;
			@media only screen and (max-width: $extrasmalldesktopview){
				width: $whizzimolibrary-card-1280 !important;
			}
			@media only screen and (max-width: $tabView){
				width: $whizzimolibrary-card-1024 !important;
			}
		}
	}
	.drag-drop-box-whizzimoLibrary{
		.new-workbook{
			@media only screen and (max-width: $extrasmalldesktopview){
				width: $card-width-1280 !important;
			}
			@media only screen and (max-width: $tabView){
				width: $card-width-1024 !important;
			}
		}
		.content{
			margin: 0px 9px 9px;
			background-color: $primary-dark-background-color;
			@media only screen and (max-width: $extrasmalldesktopview){
				width: $whizzimolibrary-card-1280 !important;
			}
			@media only screen and (max-width: $tabView){
				width: $whizzimolibrary-card-1024 !important;
			}
		}
	}

	.whizzimolibraries_header{
		background: $primary-dark-background-color;
		display: flex;
		justify-content: space-between;
		padding: 0px 24px;
		align-items: center;
		.header{
			h2{
				font-size: 32px;
				color: $primary-dark;
			}
		}
		.course-button{
			height: 40px !important;
			width: auto !important;
			box-shadow: none;
			right: 15px;
			min-width: auto;
			@include fill-button;
		}
	}
	.mat-tab-header{
		background: $primary-dark-background-color;
		border: none;
		.mdc-tab__content {
			color: $whizzimo-mat-tab-label;
			text-transform: uppercase;
			font-weight: 600;
		}
		.mat-ink-bar{
			height: 4px;
			background-color: $whizzimo-mat-tab-label;
		}
	}
	.mat-mdc-tab-body-content {
		padding: 24px 24px;
		.new-workbook {
			height: 125px;
			min-width: 200px;
			width: 18.8%;
			border-radius: 8px;
			background: $newworkbook-bg;
			background-image: $border-dash-image;
			border: none;
			justify-content: space-around;
			display: flex;
			flex-direction: column;
			margin: 0px 9px 9px;
			span{
				color: $interface-color-light-medium;
				text-transform: uppercase;
				font-size: 14px;
			}
		}
		.mat-mdc-card-header {
			color: $primary-dark;
			text-align: left;
			font-size: 16px;
			font-weight: 600;
			.mat-mdc-card-header-text {
				margin: 0 0px !important;
			}
		}
		.add_to_course{
			padding-left: 10px;
			padding-right: 10px;
			.mdc-button__label{
				color: $interface-color-light-medium;
				text-transform: uppercase;
				font-size: 14px;
			}
		}
		.Added{
			padding-left: 10px;
			padding-right: 10px;
			.mdc-button__label{
				color: #fff;
				text-transform: uppercase;
				font-size: 14px;
			}
		}
		.remove{
			padding-left: 10px;
			padding-right: 10px;
			.mdc-button__label{
				color: #ff7463;
				text-transform: uppercase;
				font-size: 14px;
			}
		}
		.ellipsis{
			position: absolute;
			right: 10px;
			.mdc-button__label{
				color: $interface-color-light-medium;
				opacity: 0.32;
			}
		}
		.sharing_from{
			margin: 24px 0px 10px;
			width: 209px;
			position: relative;
			.sharing_from_input{
				border: 1px solid rgba($primary-dark, 0.08);
				padding: 10px 0px 10px 40px;
				border-radius: 50px;
				outline: none;
				background-color: $whizzimo-input-background-transparent;
				color: rgba($whizzimo-black-white, 0.87);
				font: inherit;
				&::placeholder{
					color: rgba($whizzimo-black-white, 0.87);
				}
				&::-moz-placeholder{
					color: rgba($whizzimo-black-white, 0.87);
				}
				&::-webkit-input-placeholder{
					color: rgba($whizzimo-black-white, 0.87);
				}
			}
			&::before{
				position: absolute;
				content: "";
				display: block;
				width: 24px;
				height: 24px;
				background-image: url($search-icon);
				top: 7px;
				left: 10px;
			}

		}
		.length_p{
			color:rgba($primary-dark, 0.56);
			font-size: 16px;
		}
		.mat-progress-bar {
			border-radius: 50px;
		}
	}

}

//add-wordbook-modalbox

.add-wordbook-modalbox{
	.mat-mdc-dialog-container {
		border-radius: 8px;
		background-color: $whizzimo-modal-background;
		.mat-mdc-dialog-title{
			color: $whizzimo-form-field-color;
			font-weight: bold;
			margin-top: 24px;
			letter-spacing: normal;
		}
		.mat-mdc-dialog-content {
			max-height: 45vh;
			border-bottom: solid 1px rgba($whizzimo-font-light, 0.08);
			border-top: solid 1px rgba($whizzimo-font-light, 0.08);
			padding: 0px 0px;

			.mat-mdc-list-item{
				border-bottom: solid 1px rgba($whizzimo-font-light, 0.08);
				margin-bottom: 0px !important;
				padding: 6px 0px 6px;
				margin-top: 0px;
				outline: none;
				height: auto !important;
				cursor: pointer;
			}
			.mdc-list-item__content{
				padding: 0px 24px !important;

				.addwork_item{
					font-size: 16px !important;
					color: $primary-dark;
					line-height: 30px !important;
				}
				.addwork_p{
					font-size: 14px !important;
					color: $whizzimo-activity-item-child;
					margin-bottom: 7px;
				}
			}
		}
		.mat-mdc-button-base{
			color: $interface-color-light-medium;
			font-size: 14px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			float: right;
			position: relative;
			top:15px;
      font-family: "Roboto", sans-serif;
      padding: 4px 24px;
		}
	}

}

// add-wordbook-myLibrary-modalbox

.add-wordbook-myLibrary-modalbox{
	width: 350px !important;
	.mat-mdc-dialog-container {
		border-radius: 8px;
		background-color: $whizzimo-modal-background;
		.mat-mdc-dialog-title{
			color: $whizzimo-form-field-color;
			font-weight: bold;
			// margin: 0 0 0px;
			padding: 19px 24px 0;
		}
		.mat-dialog-content {
			max-height: 45vh;
			padding: 0px 0px;

			.mat-mdc-list-item{
				border-bottom: solid 1px rgba($whizzimo-form-field-color, 0.08);
				margin-bottom: 0px !important;
				padding: 6px 0px 6px;
				margin-top: 0px;
				outline: none;
				height: auto !important;
			}
			.mdc-list-item__content{
				padding: 0px 24px !important;

				.workbook_option_item {
					font-size: 16px !important;
					color: $primary-dark;
					line-height: 30px !important;
				}
			}
		}
		.mat-mdc-button-base{
			color: $interface-color-light-medium;
			font-size: 14px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			float: right;
			position: relative;
			top:15px;
		}
	}

}


.header_activity_top{
	position: fixed;
    display: flex;
    height: 85px !important;
    z-index: 115 !important;
	top: 0;
	background-color: $primary-dark-background-color;
	.nav-{
		cursor: pointer;
		height: 38px;
    padding-top: 5px;
	}

.header-activity-name {
	font-size: 32px;
	font-weight: bold;
	color: $primary-dark;
	height: 150px;
	align-items: center;
	line-height: 0px;
  }
  .header-workbook-name {
	font-size: 14px;
	color: $interface-color-light-medium;
	position: relative;
	height: 30px;
	align-items: center;
	text-transform: uppercase;
  }
  .mat-ripple-element{
	display: none;
	}
  }

  /*===  add-course-modalbox  ===*/

  .add-course-modalbox{
	  width: 350px !important;
	  .mat-mdc-dialog-container {
		padding: 0px;
		border-radius: 8px;
		background-color: $whizzimo-modal-background;
		.mat-mdc-dialog-title {
			margin: 0 0 20px;
			display: block;
			padding: 19px 24px;
			color: $primary-dark;
			font-weight: bold;
			font-size: 20px;
			border-bottom: 1px solid rgba($whizzimo-form-field-color, 0.08);
		}
		.mat-mdc-dialog-content {
			margin: 0 0px;
			padding: 0 24px;
			max-height: 66vh;
			.add_cousre_modal_p_course{
				color: $primary-dark;
				font-weight: bold;
				font-size: 16px;
				margin: 0 0 16px;
			}
			.add_cousre_modal_subscription{
				color: grey;
				font-weight: bold;
				font-size: 16px;
				margin-top: 0;
				.dark-theme & {
					color: white !important;
				}
			}
			.mat-mdc-form-field {
				width: 100%;
				.mat-mdc-form-field-wrapper {
					padding-bottom: 0 !important;
				}
				.mat-mdc-form-field-infix {
					color: $whizzimo-darkblue-white;
					font-size: 16px;
				}
			}
			.course_description{
				margin-top: 12px;
				.mat-form-field-appearance-outline {
					.mat-mdc-form-field-infix {
						height: 59.5px;
					}
				}
			}
		}
		.mat-mdc-dialog-actions {
			padding: 0px 10px;
			margin-bottom: 0px;
			justify-content: flex-end;
			.mat-mdc-button-base{
				@include modal-cancel-button;
			}
		}
	  }
  }

/*===  add-course-modalbox  ===*/

/* == setting page ==*/

.setting_screens{
    height:100vh;
    overflow: auto;
    background-color: $primary-dark-background !important;
    .setting_header_div{
    height: 96px;
    }
    .tile-content_div{
        height:calc(100% - 79px);
        overflow-x: hidden;
        .mat-tab-group{
            .mat-tab-header{
                top:0px;
            }
		}
		.mat-mdc-tab-header{
			border: none;
			.mat-mdc-tab-label-container{
				background-color: $primary-dark-background-color;
				.mat-mdc-tab{
					opacity: 1;
					color: rgba($whizzimo-black-white, 0.87);
					font-family: Roboto, "Helvetica Neue", sans-serif;
					font-size: 14px !important;
					font-weight: 500 !important;
					flex-grow: 0 !important;
					min-width: 160px;
				}
				.mat-ink-bar{
					height: 4px;
					background-color: $whizzimo-mat-tab-label;
				}
				.mdc-tab--active{
					opacity: 1 !important;
				}
				.mat-mdc-tab .mdc-tab-indicator__content--underline {
					body:not(.dark-theme) & {
						border-color: #866dff !important;
					}
					.dark-theme & {
						border-color: white !important;
					}
				}
			}
		}
    }
}

/* == setting page ==*/


/* == tile page  ==*/

.new-tile-container {
//   height: 100%;
  overflow: scroll;
//   padding: 0px 18px 40px;
  background-color: $primary-dark-background;
table, th, td {
	// border: 1px solid rgba(255, 255, 255, 0.4);
}
table{
	border: 10px;
	margin: auto;
    tr {
        margin-bottom: 10px;
        border: none !important;
        &:last-child {
            margin-bottom: 0px;
        }
        td {
			margin: 5px;
			min-height: 60px;
			min-width: 60px;
			overflow: visible;
        }
        // .mat-figure {
        //     &:empty {
        //         border-radius: 4px;
        //         border: none;
        //     }
        // }
	}


}
}
.inside-tile {
	display: inline-block;
	align-items: center;
	justify-content: center;
	font-weight: bolder;
	font-stretch: normal;
	font-size: 1.6837vw;
	font-style: normal;
	line-height: 60px;
	text-align: center;
	letter-spacing: normal;
	border-radius: 4px !important;
	border: solid 1px rgba(72, 30, 159, 0.24);
	// padding-right: 10px;
	// padding-left: 10px;
	min-width: 60px;
	min-height: 60px;
	z-index: 20;
	// display: flex;
	//position: relative;
	> img {
		position: absolute;
    top: 20px;
    left: 5px;
	}
}

.tile-layout-top{
    height:74%;
    //overflow-x: scroll;
}

/* == tile page ==*/

/* == edit lesson plan page ==*/

.edit-lesson-plan-header{
	background-color: $primary-dark-background-color;
	padding: 24px 24px;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 2;
	.edit-lesson-plan-top-header{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
			.lesson-plan-date{
				color: $interface-color-light-medium;
				font-size: 14px;
				font-weight: 500;
				text-transform: uppercase;
				cursor: pointer;
			}
			.header-btn-div{
				width: 280px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.begin-lesson-btn{
					@include begin-lesson-button;
				}
				.save-later-btn{
					@include transparent-button;
				}
			}
	}
	.mat-mdc-card-title{
		padding: 0;
		.errors_field{
			color: red;
			font-size: 16px;
			display: block;
		}
		.lesson_plan_name{
			@include h1-header;
			line-height: 1;
			display: flex;
			align-items: center;
			font-size: 32px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			// color: $primary-dark;
			// margin-top: 10px;
			// margin-bottom: 10px;

		}
		.lesson_plan_input{
			// margin: 0px;
			font-size: 32px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			// color: $primary-dark;
			// margin-top: 10px;
			// margin-bottom: 10px;
			width: 40vw;

		}
		.lesson_plan_title {
			margin: 0px;
			// font-size: 32px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			// color: $primary-dark;
			margin-top: 10px;
			margin-bottom: 10px;

		}
	}

}

.missing-tiles-button{
	color: $interface-color-light-medium !important;
}
.lesson_notes{
	display: flex;
	width: 100%;
	margin: 32px 0px 0px;
	.mat-mdc-form-field-infix {
		font-size: 16px;
	}
	.lesson_notes_label{
		margin-bottom: 2px;
		font-size: 16px;
		font-weight: bold;
		color: $primary-dark;
	}
	.lesson_notes_left {
		display: flex;
		flex-direction: column;
		margin-right: 250px;

			.lesson_notes_text{
				font-size: 16px;
				color: $whizzimo-font-light;
				font-weight: normal;
			}

	}
	.lesson_notes_right {
		display: flex;
		flex-direction: column;
		.lesson_notes_right_text{
			font-size: 16px;
			color: $whizzimo-font-light;
			// opacity: 0.48;
			font-weight: normal;
		}
		input{
			font-size: 14px;
			color: $whizzimo-font-light;
			font-weight: normal;
			width: 400px;
		}
	}
}

.edit-lesson-plan-body{
	//background-color: #fff;
	// margin: 24px 24px 0px;
	// padding: 24px;
	border-radius: 8px;
	height: calc(100vh - 209px);
	overflow: auto;
	> .content {
		padding: 24px;
	}
	.long-card{
		border-bottom: 1px solid $whizzimo-border-transparent;
		border-radius: 0px;
    	z-index: 1;
		.more_words_modal_activity, .more_tiles_modal_activity{
			margin-bottom: 16px !important;
		}
		.pdf_file_step{
			color: $primary-dark;
			font-size: 16px;
			font-weight: bold;
		}
		.pdf_files{
			margin-right: 10px;
			margin-bottom: 20px;
			margin-top: 20px;
			min-height: 34px;
			background-color: transparent !important;
			border: 1px solid $whizzimo-border-transparent;
			.mdc-button__label{
				color: $primary-dark !important;
			}
		}
		.no_file_btn{
			padding: 0px;
			@include no-file-btn;
		}
	}
}

.add_step{
	// margin: 0px 24px 24px;
    // border-top: 1px solid $whizzimo-border-transparent;
    cursor: pointer;
	// border-bottom-left-radius: 8px;
	// border-bottom-right-radius: 8px;
	// border-top-left-radius: 0px;
	// border-top-right-radius: 0px;
  > .long-card {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
	span{
		font-size: 14px;
		  font-weight: 500;
		  color: $interface-color-light-medium;
		  text-transform: uppercase;
	}
	mat-card {
		border-top-left-radius: 0;
		border-top-right-radius: 0;

		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;

	}
}

/*== edit lesson plan page ==*/

/*== lond card Forms==*/

.long-card{
	margin: 0;
	background-color: $primary-dark-background-color;
	box-shadow: none !important;
	padding: 24px 24px !important;

	.long-card-header {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 24px;
		.long-card-header-right img{
			margin-right: 8px;
		}
		.long-card-header-right img:first-child{
			margin-right: 20px;
		}
		.long-card-header-right img:last-child{
			margin-left: 12px;
			margin-right: 0px;
		}
		.long-card-header-left{
			display: flex;
			span{
				@include h2-header;
			}
		}
	}

	// .mat-mdc-form-field-infix{
	// 	display: block;
	// 	position: relative;
	// 	flex: auto;
	// 	min-width: 0;
	// 	width: 157px;
	// 	height: 54px;
	// 	border-radius: 4px;
	// 	border: solid 1px rgba($primary-dark, 0.64);
	// 	padding: 0 24px !important;
	// 	display: flex;
	// 	align-items: center;
	// 	color: $primary-dark;

	// 	.mat-select-value-text {
	// 		color: $primary-dark;
	// 		font-size: 16px;
	// 	}
	// }
	.mat-mdc-form-field-underline{display:none}
	.mat-mdc-form-field-appearance-legacy{
	.mat-mdc-form-field-wrapper{padding-bottom: 0;}
	}
	.mat-mdc-form-field-label {
		top: 25px;
		left: 10px;
		color: $primary-dark;
		font-size: 16px;
		padding: 5px 10px;
		background: $whizzimo-white-darkblue;
		width: auto !important;
	}
	&.mat-mdc-form-field-should-float {
		.mat-mdc-form-field-label {
			font-size: 13px;
			background-color: $whizzimo-white-darkblue;
			padding: 5px 10px;
			width: auto;
			top: 20px;
			left: 16px;
		}
	}

	.words_shuffle_btn_div{
		display: inline-flex;
		height: 56px;
		align-items: flex-start;
    	margin-left: 16px;
		.words_view_input{
			.mat-mdc-text-field-wrapper{
				// display: block;
				// position: relative;
				// flex: auto;
				// min-width: 0;
				width: 94px;
				height: 56px;
				// border-radius: 4px;
				// border-top-right-radius: 0px;
				// border-bottom-right-radius: 0px;
				// border: solid 1px rgba($primary-dark, 0.64);
				padding: 0 24px !important;
				display: flex;
				align-items: center;
				color: $primary-dark;

				.mat-select-value-text {
					color: $primary-dark;
					font-size: 16px;
				}
			}
		// 	.mat-mdc-form-field-underline{display:none}
		// 	.mat-mdc-form-field-appearance-legacy{
		// 	.mat-mdc-form-field-wrapper{padding-bottom: 0;}
		// 	}
		// 	.mat-mdc-form-field-label {
		// 		top: 29px;
		// 		left: 10px;
		// 		color: $primary-dark;
		// 		font-size: 16px;
		// 		padding: 5px 10px;
		// 	}
		// 	&.mat-mdc-form-field-should-float {
		// 		.mat-mdc-form-field-label {
		// 			font-size: 13px;
		// 			background-color: $whizzimo-white-darkblue;
		// 			padding: 5px 10px;
		// 			width: auto;
		// 			top: 20px;
		// 			left: 16px;
		// 		}
		// 	}
		}
		.shuffle_btn{
			width: 94px;
			height: 56px;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			border: 1px solid $interface-color-light-medium;
			border-left: none;
			color: $interface-color-light-medium;
			font-size: 14px;
			text-transform: uppercase;
			padding: 0;
			background: transparent;
      &:disabled {
        border-color: gray !important;
        color: gray !important;
      }
		}

	}

	.starting_view_dropdown{
		.mat-mdc-form-field-infix{
			display: block;
			position: relative;
			flex: auto;
			min-width: 0;
			width: 191px;
			height: 56px;
			padding: 0 24px !important;
			display: flex;
			align-items: center;
			color: $primary-dark;

			.mat-select-value-text {
				color: $primary-dark;
				font-size: 16px;
			}
		}
		.mat-mdc-form-field-underline{display:none}
		.mat-mdc-form-field-appearance-legacy{
		.mat-mdc-form-field-wrapper{padding-bottom: 0;}
		}
		.mat-mdc-form-field-label {
			top: 29px;
			left: 10px;
			color: $primary-dark;
			font-size: 16px;
			padding: 5px 10px;
		}
		&.mat-mdc-form-field-should-float {
			.mat-mdc-form-field-label {
				font-size: 13px;
				background-color: #fff;
				padding: 5px 10px;
				width: auto;
				top: 20px;
				left: 16px;

				.dark-theme & {
					background-color: #18112f !important;
				}
			}
		}
	}
	.missing_tile_btn{
		border: 1px solid $primary-dark;
		color: $primary-dark;
		font-size: 14px;
		height: 57px;
		background-color: transparent;
	}

	.disabled_field{
		.mat-mdc-form-field-infix{
			border: 1px solid #ccc6d7 !important;

			.mat-mdc-form-field-label{
				color: #ccc6d7 !important;
			}
		}
	}

	.example-box{
		height: 56px;
		.mat-button-wrapper {
			font-size: 24px;
			//color: $primary-dark;
			font-weight: bold;
			position: relative;
			padding: 0px 0px 0px 24px;
		}
	}

	.cdk-drop-list{
		.mat-mdc-button-base{
			height: 56px;
			// background-color: $primary-dark-background;
			border: 1px solid $whizzimo-border-transparent !important;

			.mdc-button__label {
				font-size: 24px;
				//color: $primary-dark;
				font-weight: bold;
				position: relative;
				padding: 0px 0px 0px 30px;
				color: rgba($whizzimo-black-white,0.87);

				&::before{
					content: "";
					position: absolute;
					width: 24px;
					height: 24px;
					// background-image: url($add-circle);
					background-repeat: no-repeat;
					left: 0;
					top: 3px;
				}
			}

			&:not(.cdk-drag-disabled) {
				.mdc-button__label::before {
					background-image: url($move-bg);
				}
			}
			&.text-button {
				border: 0 !important;
				cursor: default;
				.mdc-button__label::before {
					background-image: none !important;
				}
			}
		}

		.moveIcon {
			.mdc-button__label {
				&::before{
					content: "";
					position: absolute;
					width: 24px;
					height: 24px;
					background-image: url($add-circle);
					background-repeat: no-repeat;
					left: 0;
					top: 3px;
				}
			}
		}
	}
	.custom-margin-activity-popup {
		margin-right: 24px !important;
		margin-bottom: 24px;

		.mat-option[aria-disabled='true'] {
			opacity: 0.32;
		}
	}
}

/*== lond card Forms==*/

/*== registration  page  ==*/

#registration_container{
    margin: 0px;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin-right: auto;
	margin-left: auto;
	display: flex;

	.left-box{
		width: 72px;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		padding: 32px 0px;
		background-color: $primary-dark;
		position: fixed;
		left: 0;
		top: 0;
		img{
			border-radius: 4px;
		}
	}
	.right-box{
		width: calc(100% - 72px);
		float: left;
		margin-left: 72px;
		.registration_header{
			height: 153px;
			background-color: #fff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 24px;
			.registration_header_left{
				display: flex;
				flex-direction: column;
				span{
					color: $interface-color-light-medium;
					font-weight: 500;
					font-size: 14px;
					text-transform: uppercase;
					margin: 0 0 8px;
				}
				h1{
					@include h1-header;
					margin: 0 0 16px;
					line-height: 1;
				}
				p{
					@include paragraph;
					margin: 0;
				}

			}
			.registration_header_right{
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				height: 91px;
					a{
						@include registration-login-button;
					}
			}
		}
		.form_container {
			width: 435px;
			background: #fff;
			border-radius: 8px;
			margin: 24px;
			padding: 32px 24px;

			.mat-mdc-form-field {
				width: 100%;

				.mat-input-element {
					color: $primary-dark !important;
					font-size: 16px !important;
				}

				.mat-mdc-form-field-label {
					color: rgba($primary-dark, 0.56);
				}
			}

			.mdc-label {
				white-space: normal;
				color: $primary-dark;
				font-size: 16px;
				font-weight: 500;
				a{
					color: $interface-color-light-medium !important;
				}
			}
			.mat-mdc-checkbox{
				margin-bottom: 18px;
    			display: flex;
				.mdc-checkbox {
					margin: 0px 16px 0px 0px;
					top: 3px;
					width: 18px;
					height: 18px;
					.mdc-checkbox__background{
						border:2px solid rgba($primary-dark, 0.16);
					}
				}

			}
			.mat-select-value-text{
				color:$primary-dark;
			}
			.mat-select-arrow{
				color:$primary-dark;
			}
			.mat-mdc-checkbox-checked {
				.mdc-checkbox__background {
					background-color: transparent !important;
					border:2px solid $interface-color-light-medium !important;
					.mdc-checkbox__checkmark-path {
						stroke: $interface-color-light-medium !important;
					}
				}
				.mdc-checkbox__checkmark-path {
					stroke-width: 3.13333px;
				}
			}

			.vp-bit{
				span{
					margin-right: 16px;
					color: $primary-dark;
				}
			}
			.alert{
				margin: 0px 0px 20px;
				i{
					margin-right: 16px;
					color: $primary-dark;
				}
			}
			.passwords_match{
				margin: 0px 0px 20px;
			}
			.register_btn{
				@include register-button;
			}
		}
	}
}

/*== registration  page  ==*/

.workbook-body{
	.mat-mdc-tab-header {
		padding: 0px 22px;
		background-color: $primary-dark-background-color;
		.mdc-tab__content {
			opacity: 1;
			font-size: 14px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 0.4px;
			text-align: center;
			color: $whizzimo-mat-tab-label;
			text-transform: uppercase;
		}
		.mdc-tab--active {
			opacity: 1 !important;
			.mdc-tab__content {
				opacity: 1 !important;
			}
		}
		.mat-mdc-tab-label-container{
			.mat-ink-bar {
				height: 4px;
				background-color: $whizzimo-mat-tab-label;
			}
		}
	}
}

.workbook_builder_filter{
	.help_icon{
		position: relative;
		top: 2px;
		font-size: 18px;
		left: 10px;
	}
}

.demo_plan_wordlist{
	margin-left: 10px;
	margin-right: 10px;
	padding: 20px 0;
	font-size: 14px;
	color: #210854;
	text-align: center;
}
.workbook_builder_filter_main{
	background-color: $primary-dark-background-color !important;
	// border-radius: 8px;
	.filter-close{
		span{
			color: $whizzimo-black-white;
		}
	}
}
.words_container_filter{
	background-color: $primary-dark-background !important;
	.filters-container{
		.workbook_builder_filter_main {
			.filter-body{
				.filter-header{
					.filter-title{
						color: $primary-dark;
					}
					.filter-description{
						color: $whizzimo-font-light;
					}
				}
			}
		}
	}
	.results-container{
		//background-color: $secondary-color;
		margin-right: 24px;
		.word-list-header{
			background-color: $whizzimo-dark-purple;
			color: $secondary-color;
			font-size: 20px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
		}
		.word-panel{
			background-color: $whizzimo-white-darkblue;
			.word-content{
				color: $primary-dark;
			}
		}
	}
	.selection-container{
		background-color: $primary-dark-background-color;
	}
}
.sentences-and-more-container{
	padding: 0 0px;
	.search-container{
		background-color: $primary-dark-background !important;
	}
	.section-container{
		background-color: $primary-dark-background !important;
		padding: 0 24px 24px;
		border-radius: 0 !important;
		.section{
			padding: 24px;
			background-color: $primary-dark-background-color;
			// border-radius: 8px;
			.section-header{
				.section-header-text{
					h2{
						font-size: 20px;
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: 1;
						letter-spacing: normal;
						color: $primary-dark;
						margin: 0 0 8px;
					}
					.description{
						color: $whizzimo-font-light;
						margin: 0 0 24px;
					}
				}
			}
			&:not(:first-child) {
				border-top: solid 1px #f1edf8;
				.dark-theme & {
          border-top: 1px solid rgba(241, 237, 248, 0.12) !important;
				}
			}
			&:first-child {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
			}
			&:last-child {
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		}
	}
	.divider{
		width: 100%;
		height: 1px;
		border: solid 1px $whizzimo-border-transparent;
	  }
	  .section-button{
		color: $primary;
	  }
	  .content-card{
		display: flex;
		// align-items: center;
		padding: 10px;
		border-radius: 4px;
		border: solid 1px $whizzimo-border-transparent;
		background-color: transparent;
		span{
		  &:first-child{
			color: rgba($whizzimo-black-white, 0.87);
		  }
		}
		button{
			background-color: transparent;
			.mdc-button__label{
				color: rgba($whizzimo-black-white, 0.87);
			}
		}
	  }
	  .card-text{
		font-size: 24px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1;
		letter-spacing: normal;
		color: $primary-dark;
	  }
	  .new-card-button{
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		color: $primary;
		border-color: $primary;
		padding: 10px;
	  }
}
.workbook_item{
	.activity_item_child{
		font-size: 14px !important;
		color: $whizzimo-font-light;
		margin-bottom: 7px;
	}
}


.custom_filter_card_content{
	.custom_input_content{
		textarea{
			width: 170px;
			height: 61px;
			font-size: 16px;
			padding: 10px 17px;
			background: transparent;
			box-shadow: none;
			border: 1px solid $whizzimo-border-transparent;
			color: rgba($whizzimo-black-white, 0.87);
			font-family: 'Roboto';
		}
	}
	.text-box-wrapper{
		border: 1px solid $whizzimo-border-transparent;
		color: rgba($whizzimo-black-white, 0.87);
	}
}

.quick-unselected{
	color: $primary-dark;
}
.workbook_builder_header_main{
	background-color: $primary-dark-background-color;
	.workbook_builder_header{
		min-width: calc(100% - 230px);
		.workbook-title{
			color: $primary-dark;
		}
		.workbook-owner{
			color: $interface-color-light-medium;
		}
		.workbook-description{
			color: $whizzimo-font-light;
		}
		.mat-mdc-form-field {
			width: 90% !important;
		}
	}
}
.sentences-search-box{
	.search_img{
		background-image: url($search-icon);
		width: 22px !important;
		height: 24px;
	  }
}
.files_search_container{
	.search-box{
		padding: 8px !important;
		background-color: $whizzimo-input-background-transparent;
		.search_img{
			background-image: url($search-icon);
			width: 22px !important;
			height: 24px;
			margin-right: 10px;
		  }
		  input{
			  color: rgba($whizzimo-black-white, 0.87);
		  }
	}
}


.mat-form-field-appearance-outline .mat-mdc-form-field-outline{
	color: $whizzimo-form-field-outline;
}

.plan-description{
	background-color: $primary-dark-background-color;
	// border: $whizzimo-border-transparent 1px solid;
	width: 220px;
	border: 1px solid rgb(234, 236, 238);
	margin-bottom: 16px;
	z-index: 1;
	transition: transform 0.5s ease-in-out;
	&:hover {
		transform: scale(1.02);
		z-index: 2;
		transition: transform 0.5s ease-in-out;

	}
	.plan-price-text{
		color: rgba($whizzimo-black-white, 0.87);
	}
	.plan-price-sub-text{
		color: rgba($whizzimo-black-white, 0.87);
	}
}
.account_note{
	// color: rgba($whizzimo-black-white, 0.87);
	font-size: 16px;
	color: #777183;
}


.mat-mdc-select-panel{
	background-color: $whizzimo-modal-background;
	box-shadow: 0 4px 9px 0 rgb(33 8 84 / 21%);
}
.mat-mdc-select-arrow {
    color: rgba($whizzimo-black-white, 0.54);
}

app-word-radio-filter{
	// .mat-radio-button{
	//   color: rgba($whizzimo-black-white, 0.87);
	// }
	// .mat-radio-outer-circle {
	// 	border-color: rgba($whizzimo-black-white, 0.54);
	// }
	// mat-select{
	// 	.mat-mdc-form-field-label {
	// 		// color: rgba($whizzimo-black-white, 0.87);
	// 	}
	// }
}
.tile_footer{
	background-color: $primary-dark-background-color;
}

.mat-mdc-menu-panel{
	background-color: $whizzimo-modal-background;
	box-shadow: 0 4px 9px 0 rgb(33 8 84 / 21%);
  max-width: 300px !important;
}

.mat-mdc-dialog-container{
	background-color: $whizzimo-modal-background;
	border: 2px solid white;

}

.tile-layout-top{
	background-color: $whizzimo-blackboard-body;
  overflow: hidden;
}
.tilelayout_div_footer{
	.tileset_name{
		color: $primary-dark;
	}
}
.custom_filter_card_content{
	.mat-mdc-form-field{
		// border: 1px solid $whizzimo-border-transparent !important;
	}
	.mat-mdc-form-field-infix{
		color: rgba($whizzimo-black-white, 0.87);
	}
}
.account-container{
	padding: 20px;
	margin: 20px;
	background-color: $primary-dark-background-color;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.account-information{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	.account-information-title{
		h3{
		color: $primary-dark;
		}
	}
	.detail{
	color: $primary-dark;
	font-weight: 500;
	font-size: 16px;
	}
	button{
		background-color: transparent;
		.mdc-button__label{
			color: $interface-color-light-medium;
		}
	}
	.info{
	color: $whizzimo-font-light;
	margin-left: 20px;
	font-size: 16px;
	font-weight: 500;
	}
	.divider{
	width: 100%;
	border-bottom: $whizzimo-border-transparent 1px solid;
	margin-top: 20px;
	margin-bottom: 20px;
	}

	.card-input{
	border-radius: 4px;
	width: 100% !important;
	height: 56px !important;
	font-size: 16px !important;
	color: $primary-dark;
	border: 1px solid $primary-dark !important;
	padding-left: 10px;
	}
}
.account-modal{
	padding: 20px;
	margin: 20px;
	// background-color: $primary-dark-background-color;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.account-information{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	.account-information-title{
		h3{
		color: $primary-dark;
		}
	}
	.detail{
	color: $primary-dark;
	font-weight: 500;
	font-size: 16px;
	}
	button{
		background-color: transparent;
		.mdc-button__label{
			color: $interface-color-light-medium;
		}
	}

	.info{
	color: $whizzimo-font-light;
	margin-left: 20px;
	font-size: 16px;
	font-weight: 500;
	}
	.divider{
	width: 100%;
	border-bottom: $whizzimo-border-transparent 1px solid;
	margin-top: 20px;
	margin-bottom: 20px;
	}

	.card-input{
	border-radius: 4px;
	width: 100% !important;
	height: 56px !important;
	font-size: 16px !important;
	color: $primary-dark;
	border: 1px solid $primary-dark !important;
	padding-left: 10px;
	}
}

.agreement-text{
	margin-left: 20px !important;
	color: rgba($primary-dark, 0.56) !important;
	font-size: 14px;
}
.blackboard-wrap {
	height: 100vh;
	background-color: $primary-dark-background;
	overflow: hidden;
}
.mini-tile-wrap {
	height: 100vh;
	background-color: $primary-dark-background;
}

.blackboard_footer{
	background-color: $primary-dark-background-color;
	touch-action: none;
  top: 0;
	.line{
		height: 62px;
		border-bottom: 2px solid $whizzimo-border-transparent;

		}
}
.account_ht3{
	color: $primary-dark;
}
.files_search_container{
	background-color: $primary-dark-background;
}
.file-container{
	background-color: $primary-dark-background;
	.upload_info{
		// color: $whizzimo-black-white;
    color: rgba(13, 2, 35, 0.5607843137);
    .dark-theme & {
      color: white !important;
    }
	}
}
.bg_transparent_dark_salmon{
	background-color: transparent;
	color: $interface-color-soft-darkar !important;
}
.bg_salmon_btn{
	background-color: $interface-color-soft-medium !important;
	.mdc-button__label{
		color: #210854;
	}
}
.bg_transparent_light_purple{
	background-color: transparent;
	.mdc-button__label{
		color: $interface-color-light-medium;
	}
}

.modal-container{
	.header{
		color: $primary-dark;
	}
}
.workbook-body{
	app-workbook-builder-words{
		.button-container{
			.find-words-button{
				background: $whizzimo-purple !important;
				color: white !important;
			}

			.save-close {
				color: $whizzimo-purple !important;
			}
		}
	}
	.mat-mdc-tab-header{
		border: none;
		border-bottom: solid 1px rgba(134, 109, 255, 0.48);
		.mat-mdc-tab-label-container{
			background-color: $primary-dark-background-color;
			// padding: 0px 24px;
			.mat-mdc-tab{
				opacity: 1;
				font-family: Roboto, "Helvetica Neue", sans-serif;
				font-size: 14px !important;
				font-weight: 500 !important;
				flex-grow: 0 !important;
				min-width: 160px;
			}
			.mat-ink-bar{
				height: 4px;
				background-color: $whizzimo-mat-tab-label;
			}
			.mdc-tab--active{
				opacity: 1 !important;
			}
			.mat-mdc-tab .mdc-tab-indicator__content--underline {
				border-top-width: 4px !important;
				body:not(.dark-theme) & {
					border-color: $interface-color-darkar-medium !important;
				}
			}
		}
	}
}

.file-content{
	background: $primary-dark-background-color;
	// border-radius: 8px;
	.file-header{
		h2{
			color: $primary-dark;
			margin: 20px 0 8px;
		}
		// span{
		// 	color: $whizzimo-font-light;
		// }
	}
	.description{
		color: $whizzimo-font-light;
	}
	&:not(:first-child){
		border-top: solid 1px #f1edf8;
		.dark-theme & {
			border-top: 1px solid rgba(241, 237, 248, 0.12) !important;
		}
	}
	&:first-child {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
	&:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}
.file-card-container{
  border: solid 1px $whizzimo-lightpurpale;
  //background-color: $primary-dark-background;
  color: $whizzimo-black-white;
	span{
		&:first-child{
		  color: rgba($whizzimo-black-white, 0.87);
		}
	  }
	  button{
		background-color: transparent;
		.mdc-button__label{
		  color: rgba($whizzimo-black-white, 0.87);
		}
	  }
	  .button-active{
		background-color: #866dff;
		border: 1px solid #866dff;
		height: 35px;
		.mdc-button__label{
		  color: white;
		}
	 }
	 .button-inactive{
		background-color: transparent;
		.mdc-button__label{
		  color: rgba($whizzimo-black-white, 0.87);
		}
	  }


}
.page_tutorials{
	background: $primary-dark-background;
}
.mat-drawer-content{
	background-color: $primary-dark-background;
}
.sharing_modal_delete{
	.mat-mdc-dialog-title{
		color: $primary-dark;
	}
	.mat-dialog-content{
		p{
			color: $whizzimo-font-light;
			margin: 0 0 0;
		}
	}
}
.TutorialDialog_modal{
	.mat-mdc-dialog-title{
		color: $primary-dark !important;
		padding: 24px 24px 19px;
	}
}

.sentences-search-box{
	background-color: $whizzimo-input-background-transparent;
	input{
		color: rgba($whizzimo-black-white, 0.87);
	}
}

.word_group_bank{
	h2{
		color: $primary-dark;
	}
	table{
		.card-text{
			color: $primary-dark !important;
		}
	}

}
.word_group_bank_menu_item{
	.mat-menu-item{
		color: $primary-dark;
	}
}
.file_card_menu{
	.mat-menu-item{
		color: $primary-dark;
	}
}

.words_tile_box{
	background-color: transparent !important;
	border: 1px solid $whizzimo-border-transparent !important;
	.mdc-button__label{
		color: $primary-dark !important;
	}
}

.sentence_example_box{
	background-color: transparent !important;
	border: 1px solid $whizzimo-border-transparent !important;
	.mdc-button__label{
		color: $primary-dark !important;
	}
}
.edit_words_tile_mattab{
	.mdc-tab__content{
		color: $whizzimo-mat-tab-label;
	}

  .mat-mdc-tab-body-content {
    overflow: hidden;
  }

  .mat-mdc-tab-label-container{
	background-color: $primary-dark-background-color;
	.mat-mdc-tab{
		opacity: .6;
		color: rgba($whizzimo-black-white, 0.87);
		font-family: Roboto, "Helvetica Neue", sans-serif;
		font-size: 14px !important;
		font-weight: 500 !important;
		flex-grow: 0 !important;
		min-width: 160px;
	}
	.mat-ink-bar{
		height: 4px;
		background-color: $whizzimo-mat-tab-label;
	}
	.mdc-tab--active{
		opacity: 1 !important;
	}
	.mat-mdc-tab .mdc-tab-indicator__content--underline {
		border-color: #866dff !important;
	}
}
}
.pdf-right-control{
	color: $primary-dark;
}


.input-divider{
	color: rgba($whizzimo-lightgrey-white, 0.56);
}

.same_plan_title{
	color: $primary-dark;
}
.same_plan_content{
	color: $whizzimo-font-light;
	font-weight: 500;
}
.demoplan_content{
	color: $whizzimo-font-light;
	font-weight: 500;
}
.add_card_menu_h3{
	color: $primary-dark;
}
.focus-theme{
	.side-container{
		border-right: solid 1px rgba($interface-color-light-medium, 0.48);
	}
}
.delete_file_dialog{
	.popup-header{
		h1{
			color: $primary-dark;
		}
	}
}

.filter-card-tiles-header{
	.search-box{
		span{
			color: rgba($whizzimo-black-white, 0.72);
		}
		input{
			color: rgba($whizzimo-black-white, 0.72);
		}
	}
}
.quick_container_h3{
	color: $primary-dark;
	font-size: 16px;
}
.pairing-section-container-span{
	color: $primary-dark;
}
.pairing-section-container-h3{
	color: $primary-dark;
	margin-top: 0 !important;
}

.mat-bottom-sheet-container{
	background-color: #481e9f !important;
}

input.editable-text {
	color: gray;
	font-weight: normal;
	border: none;
	outline: none;
}

.hidden-btn {
	visibility: hidden;
}

mat-mdc-list-item.selected {
	background: #EEEEEE;
	&.dark-mode {
		background: #866DFF;
	}
}

// Add new course
.add-course.course-name, .for-internal-container {
	.mat-mdc-form-field-wrapper {
		height: 56px;
		.mat-mdc-form-field-flex {
			height: 100%;
		}
		.mat-mdc-form-field-label-wrapper {
			top: -16px;
		}
		.mat-mdc-form-field-infix {
			padding: 0.5em 0;
		}
		.mat-mdc-form-field-wrapper {
			margin: 0;
		}
	}
	mat-label {
		color: #0d0223;
		opacity: 0.56;
	}

	.dark-theme & {
		mat-label {
			color: white !important;
		}
		input {
			color: white;
		}
	}
}

.add-course.course_description {
	.mat-mdc-form-field-infix {
		padding: 11px 0;
	}
	textarea {
		resize: none;
		font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: normal;
		&.mat-input-element {
			margin: 0;
		}
	}
	.mat-mdc-form-field-wrapper {
		margin: 0;
	}
	mat-label {
		color: #0d0223;
		opacity: 0.56;
	}

	.dark-theme & {
		mat-label {
			color: white !important;
		}
		input {
			color: white;
		}
	}
}

.add_cousre_modal_subscription {
  .dark-theme & {
    color: white !important;
  }
}

.custom-confirm-dialog {
  width: 360px;
	.mat-mdc-dialog-container {
		display: flex;
		> * {
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		.mat-mdc-dialog-title {
			color: #210854;
			margin-bottom: 0;
			padding: 19px 24px;
			letter-spacing: normal;
			.dark-theme & {
				color: white !important;
			}
		}

		.mat-mdc-dialog-content {
			color: rgba(13, 2, 35, 0.56);
			margin: 0;
			padding: 4px 24px;
			letter-spacing: normal;
			line-height: normal;
			overflow-x: hidden;
			.dark-theme & {
				color: white !important;
			}
		}
		.mat-mdc-dialog-content.show-scores-dialog-content {
			// margin-top: 24px;
			margin-top: 8px;
			align-self: flex-start;
			.value {
				font-weight: 500;
				margin-left: 8px;
			}
		}

		.mat-mdc-dialog-actions {
			min-height: unset;
			padding: 0 14px 10px;
			justify-content: flex-end;

			.confirm-btn {
				padding: 10px;
				line-height: unset;
				background-color: transparent !important;
			}

			.delete-btn {
				padding: 10px;
				line-height: unset;
				background-color: transparent !important;

				.mdc-button__label {
					color: var(---mdc-text-button-label-text-color);
					font-weight: bold !important;
				}
			}

			button {
				padding: 10px;
				line-height: unset;
				background-color: transparent !important;
			}
			.mdc-button__label {
				font-weight: bold !important;
			}
		}
	}
}

.add-course-item .mdc-list-item__primary-text {
	color: $whizzimo-purple !important;
	letter-spacing: normal;
}

.side_bar_display mat-expansion-panel-header > span {
	display: none;
}

.custom-form-field {
	label.mat-mdc-form-field-label {
		font-size: 13px;
    background-color: white;
    padding: 5px 10px;
    width: auto !important;
    top: 21px;
    left: 16px;
		.dark-theme & {
			background-color: #18112f !important;
		}
	}
}

.word-selection-list {
	.mdc-list-item__content {
    flex-direction: row !important;
  }
	.mat-list-text {
		.dark-theme & {
			color: white;
		}
	}
	.mat-pseudo-checkbox {
		margin-right: 8px;
		border: 2px solid $whizzimo-purple;
		color: $whizzimo-purple;
		&.mat-pseudo-checkbox-checked {
			color: $whizzimo-purple;
			border-color: $whizzimo-purple;
		}
	}
}

// CUSTOM MATERIAL COMPONENTS
mat-mdc-checkbox.whizzimo-mat-mdc-checkbox {
	color: $primary-color;
	.mat-mdc-checkbox-frame {
		border-color: $interface-color-light-medium;
	}
	&:not(.mat-mdc-checkbox-checked) {
		.mat-mdc-checkbox-frame {
			border-color: rgba(33,8,84, 0.24);
		}
	}
	.mdc-checkbox__checkmark-path {
		stroke: $interface-color-light-medium;
	}
}
.dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox {
	color: white !important;
	.mat-mdc-checkbox-frame {
		border-color: white !important;
	}
	&:not(.mat-mdc-checkbox-checked) {
		.mat-mdc-checkbox-frame {
			border-color: rgba(211, 211, 211, 0.3) !important;
		}
	}
	.mdc-checkbox__checkmark-path {
		stroke: white !important;
	}
}

// .course-workbook-edit-tiles-container {
	.sort-tile-btn {
		.dark-theme & {
			color: #866dff !important;
		}
	}
	.example-box.letter-tile {
		min-width: 72px;
    	height: 64px !important;
		padding-left: 8px;
    	padding-right: 12px;
		.mdc-button__label {
			padding-left: 24px !important;
			font-size: 32px !important;
			&::before {
				// background-image: url('/assets/svgs/regular/move.svg') !important;
				top: 10px !important;
			}
			> span {
				display: inline-block;
				min-width: 26px;
				text-align: left;
			}
		}
	}
// }

.example-box.words_tile_box {
	min-width: 72px;
    height: 56px !important;
	padding-left: 8px;
    padding-right: 12px;
	.mdc-button__label {
		padding-left: 24px !important;
		display: inline-block;
		min-width: 26px;
		text-align: left;
	}
}

.activity-content-label {
	font-size: 14px;
	font-weight: 500;
	height: 48px;
	width: 160px;
	opacity: .6;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #866dff;
	border-bottom: 2px solid #866dff;
	opacity: 1 !important;
	margin-bottom: 24px;
	// .dark-theme & {
	// 	opacity: 1 !important;
	// }
}

.text-holder::before {
	// background-image: url(move.png);
	background-image: url($move-bg);

}

.course-workbook-edit-workbook-items-container {
	.drag-n-drop-zone {
		display:inline-block;
		margin-right: 10px;
		width: 100%;
		min-height: 60px;
		position:relative;
		.text-button {
			position: absolute;
		}
		.tile-button {
			margin-right: 8px;
			margin-bottom: 8px;
			min-height: 34px
		}
	}
}

.vertical-center {
	display: flex !important;
	align-items: center;
}

mat-tab-group {
	:not(.dark-theme) & {
		.mdc-tab__content {
			.mdc-tab__text-label {
				// color: #a691d0 !important;
				color: #481e9f;
			}
		}
	}
	.dark-theme & {
		.mdc-tab__content {
			.mdc-tab__text-label {
				color: white !important;
			}
		}
		.mat-ink-bar {
			background-color: white !important;
		}
	}

}

.search-input-box {
	@extend .sharing_from;
	input {
		font-size: 16px;
		padding-right: 16px !important;
		&::placeholder {
			color: rgba(33, 8, 84, 0.32) !important;
		}
	}
	// // Highlighting the border and showing the close icon
	// input:not(:placeholder-shown) {
	// 	border: 1px solid rgba(72, 30, 159, 0.49) !important;
	// 	&::before {
	// 		visibility: none;
	// 	}
	// }
	// input[type="search"]::-webkit-search-cancel-button {
	// 	cursor: pointer;
	// 	-webkit-appearance: none;
  //   height: 24px !important;
  //   width: 22px !important;
	// 	background: url('assets/svgs/regular/x.svg');
	// 	background-repeat: no-repeat;
	// }

}

.input-error-message {
	font-size: 12px;
	text-align: left;
	margin: 0 13px;
	color: #ff8a65;
}

.mat-mdc-button[disabled="true"] {
	border-color: gray !important;
	color: gray !important;
	.mdc-button__label {
		color: #a6a6a6 !important;
	}
}

.mat-mdc-form-field-invalid .mat-mdc-form-field-outline-thick {
  color: #f44336;
}

.workbook-name-modal {
  .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
    padding: 0;
  }
}

.rename-file-dialog {
  .mat-mdc-form-field-wrapper {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.words-sort-label {
  font-size: 14px;
  color: #866dff;
}

.course-workbook-edit-tiles-container {
  .top-tiles-container {
    max-height: 210px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    min-height: 64px;
    .top-tile:last-child {
      flex: 1;
    }
  }
}

.more_tiles_modal_activity.disabled {
	color: gray;
  cursor: not-allowed;
}

.tile-updating-options {
  width: 360px;
}

.word-updating-options {
  width: 560px;
}

span.disabled, img.disabled {
  color: gray !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

mat-mdc-form-field {
  &.disabled {
    .mat-mdc-form-field-infix {
      border-color: gray !important;
      input {
        color: gray !important;
      }
    }
  }
}

.mat-button-disabled {
  color: gray !important;
  border-color: gray !important;
}

.label {
  &.disabled {
    color: gray !important;
  }
}


.workbook-edit-menu-dialog {
  max-height: 900px;
  max-width: 88vw !important;
  .mat-mdc-dialog-container {
	padding: 24px;
    overflow: hidden;
  }
}

.course-workkbook-edit-words-table-container {
  overflow: auto;
  max-height: 200px;
}

app-course-workbook-edit-menu {
  app-course-workbook-edit .long-card {
    cursor: pointer; padding: 0 !important;
  }
}

.shuffle_tiles {
  border-radius: 4px !important;
  border: 1px solid #866dff !important;
  color: #866dff !important;
  width: 94px;
  height: 58px;
  &:disabled {
    color: gray !important;
    border: 1px solid gray !important;
    cursor: default !important;
  }
}

.color-menu .mat-button-toggle-checked {
  background: #f1eeff;
}

.letter-card-dialog {
  overflow: hidden;
  .mat-mdc-dialog-container {
    padding: 0;
    border-radius: 8px !important;
    box-shadow: 0 4px 16px 0 rgba($primary-dark, 0.08) !important;
    overflow: hidden;
    border: 0 !important;
  }
  .mat-dialog-content {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
}

.mat-mdc-menu-panel.mat-menu-color {
	overflow: hidden;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-infix{
  padding: 1em 0 1em 0;
}
// .mat-mdc-form-field-type-mat-input .mat-mdc-form-field-infix {
//   border-top: 0.5em solid transparent;
// }
.mat-mdc-form-field-error-wrapper {
	font-size: 75%;
}
// .mat-expansion-panel-content {
//   font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
//   letter-spacing: normal;
// }
.mdc-list {
  padding: 0px !important;
}
.mat-mdc-list-base .mat-mdc-list-item {
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  padding: 0;
  .mdc-list-item__primary-text {
		display: flex;
		flex-direction: column;
		letter-spacing: normal;
	}
}
.mat-mdc-dialog-container {
  border-radius: 4px;
  box-shadow: $modal-shadow !important;
  .mdc-dialog__surface {
    overflow: hidden !important;
    box-shadow: none !important;
  }
  .mat-mdc-dialog-actions .mdc-button__label {
    font-size: 14px;
    font-weight: 500;
    font-family: $primary-font;
    color: #866dff;
  }
}
.mat-form-field-invalid{
  .mdc-notched-outline--upgraded {
    color: #f44336 !important;
  }
}
.mat-form-field-invalid{
  .mat-mdc-floating-label {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}
.mat-mdc-form-field-should-float{
  .mat-mdc-form-field-label {
    color: $primary-dark !important;
  }
}
.mat-form-field-appearance-outline {
  .mdc-form-field-outline {
    color: rgba($primary-dark, 0.18);
  }
}
.mat-mdc-form-field-should-float{
  .mdc-form-field-outline {
    color: $primary-dark !important;
  }
}
.mdc-text-field .mdc-floating-label {
  top: 1.84375em !important;
  margin-top: -0.25em;
}
.mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border: none;
  border-radius: 50%;
}
.mat-mdc-tab .mdc-tab__text-label {
	// color: $whizzimo-mat-tab-label !important;
    justify-content: center;
    white-space: nowrap;
	letter-spacing: normal;
}
.mat-mdc-option {
	.mdc-list-item__primary-text {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		letter-spacing: normal;
	}

	.mat-pseudo-checkbox {
		display: none;
	}
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
	border-color: #866dff !important;
    background-color: white !important;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
	color: #866dff !important;
}
.mdc-dialog__title::before {
	height: auto !important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
	.mdc-notched-outline__leading,
	.mdc-notched-outline__notch,
	.mdc-notched-outline__trailing {
		border-color: #e0e0e0;
	}
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
	.mdc-notched-outline__leading,
	.mdc-notched-outline__notch,
	.mdc-notched-outline__trailing {
		border-color: var(--mdc-theme-error, #f44336);
	}
}
